import styled from 'styled-components'

export const Container = styled.div`
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 200;
    box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
    transition: transform 0.3s ease-out;
`

export const Content = styled.div`
    width: 100%;
    background-color: white;
    display: flex;
    flex: 1;
    flex-direction: column;
`

export const LogoIcon = styled.img`
    height: auto;
    width:100%;
`
export const Close = styled.img`
    height: 17px;
    width: 17px;
`
export const Header = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0 ;
`

export const Body = styled.div`
    flex: 4;
    display: flex;
    justify-content: center;
    flex-direction: column;
`

export const Left = styled.div`
    text-align: center;
    margin-left:15px;
`
export const Right = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
`
export const Title = styled.label`
    font-size: 18px;
    font-family: 'Nexa-XBold';
    text-align: center;
    margin-bottom: 20px;
    color:var(--gray-003);
`
