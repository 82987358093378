import React from 'react';
import {
  Container,
  Label,
  Header,
  SeparatorTitle,
  Separator,
  Title,
  Icon,
  Left,
  Right,
  Spacement,
} from './ListUsers.styles';
import iconEdit from 'assets/icons/edit.png';
import PopUp from '../PopUp/PopUp.component';
import { PhoneMask } from 'utils/Masks/PhoneMask';

function ListCardsComponent({ handlers }: { handlers: any }) {
  const { userAccounts, handleSetFields, handleDeleteUser } = handlers;
  return (
    <>
      <Header>
        <SeparatorTitle>
          <Title>Nome do Usuário</Title>
        </SeparatorTitle>
        <SeparatorTitle className="notMobile">
          <Title>Login</Title>
        </SeparatorTitle>
        <SeparatorTitle>
          <Title>Email</Title>
        </SeparatorTitle>
        <SeparatorTitle>
          <Title>Documento</Title>
        </SeparatorTitle>
        <SeparatorTitle className="notMobile">
          <Title>Celular</Title>
        </SeparatorTitle>
        <SeparatorTitle>
          <Title>Opções</Title>
        </SeparatorTitle>
      </Header>
      {userAccounts?.map((user: any, index: string) => {
        return (
          <div key={index}>
            <ListCard
              handleSetFields={handleSetFields}
              user={user}
              handleDeleteUser={handleDeleteUser}
            />
          </div>
        );
      })}
    </>
  );
}

function ListCard({
  user,
  handleSetFields,
  handleDeleteUser,
}: {
  //prettier-ignore
  user: any;
  //prettier-ignore
  handleSetFields: any;
  //prettier-ignore
  handleDeleteUser: any;
}) {
  const documentNumber = user?.numeroDocumento.replace(
    /(\d{3})(\d{3})(\d{3})(\d{2})/,
    '$1.$2.$3-$4'
  );

  return (
    <Container>
      <Separator>
        <Label>{user?.nomeUsuario}</Label>
      </Separator>
      <Separator className="notMobile">
        <Label>{user?.login}</Label>
      </Separator>
      <Separator>
        <Label>{user?.email}</Label>
      </Separator>
      <Separator>
        <Label>{documentNumber}</Label>
      </Separator>
      <Separator className="notMobile">
        <Label>
          +{user?.ddi} ( {user?.ddd} ){' '}
          {user?.numCelular && PhoneMask(JSON.stringify(user.numCelular))}
        </Label>
      </Separator>
      <Separator>
        <Spacement />
        <Left>
          <Icon onClick={() => handleSetFields(user)} src={iconEdit} />
        </Left>
        <Right>
          <PopUp
            onClick={() => handleDeleteUser(user.numeroDocumento)}
            name={user?.nomeUsuario}
          />
        </Right>
        <Spacement />
      </Separator>
    </Container>
  );
}

export default ListCardsComponent;
