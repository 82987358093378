import React from 'react';
import { Container, Title } from './AuthorizationHistory.styles';
import HistoryCard from './components/HistoryCard/HistoryCard.component';

function AuthorizationHistoryScreen({ handlers }: { handlers: any }) {
  let { allCardsTransactions } = handlers;

  const verifyEmpty = () => {
    let isEmpty = true;
    if (
      allCardsTransactions.length === 1 &&
      allCardsTransactions[0]?.register.length <= 0
    ) {
      return isEmpty;
    }
    allCardsTransactions.map((transaction: any) => {
      if (transaction.register.length > 0) {
        isEmpty = false;
      }
    });

    return isEmpty;
  };

  return (
    <Container>
      {verifyEmpty() ? (
        <>
          <Title>Nenhum Histórico de Autorização disponível no momento</Title>
        </>
      ) : (
        <>
          <Title>
            <span>Transações</span>
          </Title>
          <HistoryCard allCardsTransactions={allCardsTransactions} />
        </>
      )}
    </Container>
  );
}

export default AuthorizationHistoryScreen;
