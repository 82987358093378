export function validateHasUppercase(text) {
  const hasUppercaseRegex = /.*[A-Z]+.*/g;
  return hasUppercaseRegex.test(text);
}

export function validateHasLowercase(text) {
  const hasLowercaseRegex = /.*[a-z]+.*/g;
  return hasLowercaseRegex.test(text);
}

export function validateHasNumber(text) {
  const hasNumberRegex = /.*[0-9]+.*/g;
  return hasNumberRegex.test(text);
}

export function validateMinLength(text, minLength) {
  return text.length >= minLength;
}

export function validateSpecialCharacters(text) {
  const hasSpecialCharacters = /[^A-Za-z0-9]/g;
  return hasSpecialCharacters.test(text);
}

export function isValidPassword(password) {
  try {
    if (
      !validateSpecialCharacters(password) &&
      validateHasUppercase(password) &&
      validateHasLowercase(password) &&
      validateHasNumber(password) &&
      validateMinLength(password, 8)
    ) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
  }
}

export function nameIsvValid(name) {
  return /^([A-Za-z&áàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]{2,}\s){1}([A-Za-z&áàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]{1,}\s?){1,}$/.test(
    name
  );
}

export const cleanDocument = (value) => {
  let document = value.replace(/\./g, '');
  document = document.replace(/-/g, '');
  document = document.replace(/\//g, '');
  return document;
};

export function isValidDate(dateOfBirth) {
  try {
    let date = dateOfBirth;
    const ardt = date.split('/');
    const ExpReg = new RegExp(
      '(0[1-9]|[12][0-9]|3[01])/(0[1-9]|1[012])/[12][0-9]{3}'
    );

    let error = false;

    if (date.search(ExpReg) == -1) {
      error = true;
    } else if (
      (ardt[1] == 4 || ardt[1] == 6 || ardt[1] == 9 || ardt[1] == 11) &&
      ardt[0] > 30
    ) {
      error = true;
    } else if (ardt[1] == 2) {
      if (ardt[0] > 28 && ardt[2] % 4 != 0) {
        error = true;
      }

      if (ardt[0] > 29 && ardt[2] % 4 == 0) {
        error = true;
      }
    }

    if (error) {
      return false;
    }
    return true;
  } catch (error) {
    console.log(error);
  }
}

export function validateEmail(email) {
  const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(String(email).toLowerCase());
}

export function validationDocument(document) {
  const doc = document.replace(/[^\d]+/g, '');
  if (doc === '') {
    return false;
  }
  if (doc.length === 11) {
    if (
      doc === '00000000000' ||
      doc === '11111111111' ||
      doc === '22222222222' ||
      doc === '33333333333' ||
      doc === '44444444444' ||
      doc === '55555555555' ||
      doc === '66666666666' ||
      doc === '77777777777' ||
      doc === '88888888888' ||
      doc === '99999999999'
    ) {
      return false;
    }
    let sum;
    let rest;
    sum = 0;

    for (let i = 1; i <= 9; i += 1) {
      sum += parseInt(doc.substring(i - 1, i), 10) * (11 - i);
    }
    rest = (sum * 10) % 11;

    if (rest === 10 || rest === 11) {
      rest = 0;
    }
    if (rest !== parseInt(doc.substring(9, 10), 10)) {
      return false;
    }

    sum = 0;
    for (let i = 1; i <= 10; i += 1) {
      sum += parseInt(doc.substring(i - 1, i), 10) * (12 - i);
    }
    rest = (sum * 10) % 11;

    if (rest === 10 || rest === 11) {
      rest = 0;
    }
    if (rest !== parseInt(doc.substring(10, 11), 10)) {
      return false;
    }
    return true;
  }
  if (doc.length === 14) {
    if (
      doc === '00000000000000' ||
      doc === '11111111111111' ||
      doc === '22222222222222' ||
      doc === '33333333333333' ||
      doc === '44444444444444' ||
      doc === '55555555555555' ||
      doc === '66666666666666' ||
      doc === '77777777777777' ||
      doc === '88888888888888' ||
      doc === '99999999999999'
    ) {
      return false;
    }
    let size = doc.length - 2;
    let numbers = doc.substring(0, size);
    const digits = doc.substring(size);
    let sum = 0;
    let pos = size - 7;
    for (let i = size; i >= 1; i -= 1) {
      // eslint-disable-next-line
      sum += numbers.charAt(size - i) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }
    let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    // eslint-disable-next-line
    if (result != digits.charAt(0)) return false;

    size += 1;
    numbers = doc.substring(0, size);
    sum = 0;
    pos = size - 7;
    for (let i = size; i >= 1; i -= 1) {
      // eslint-disable-next-line
      sum += numbers.charAt(size - i) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }
    result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    // eslint-disable-next-line
    if (result != digits.charAt(1)) return false;

    return true;
  }
}
