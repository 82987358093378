import React, { useEffect, useState } from 'react';
import ManageUserScreen from './ManageUser.screen';
import {
  getAllClients,
  patchUserAccount,
  updateUserStatus,
  deleteUser,
} from 'store/modules/client/client.endpoints';
import Loading from 'components/Loading/Loading.component';
import * as EmailValidator from 'email-validator';
import { PhoneMask } from 'utils/Masks/PhoneMask';

function ManageUserController() {
  const [userAccounts, setUserAccounts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [ddd, setDdd] = useState('');
  const [ddi, setDdi] = useState('');
  const [numeroCelular, setNumeroCelular] = useState('');
  const [nomeUsuario, setNomeUsuario] = useState('');
  const [listaPerfis, setListaPerfis] = useState<any>([]);
  const [edit, setEdit] = useState(false);
  const [email, setEmail] = useState('');
  const [documentNumber, setDocumentNumber] = useState('');
  const [status, setStatus] = useState('');
  const [currentAccount, setCurrentAccount] = useState({
    ddd: 0,
    ddi: 0,
    email: '',
    id: 0,
    login: '',
    nomeUsuario: '',
    numCelular: 0,
    numeroDocumento: '',
    status: '',
  });
  const [loadingButton, setLoadingButton] = useState(false);

  useEffect(() => {
    getClients();
  }, []);

  const getClients = async () => {
    try {
      const { data } = await getAllClients();
      setUserAccounts(data.usuarios);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  console.log(currentAccount);

  const handleUpdateFields = async () => {
    try {
      const emailIsvalid = EmailValidator.validate(email);

      if (!ddd || !ddi || !email || !nomeUsuario || !numeroCelular) {
        return alert('Preencha todos os campos obrigatórios.');
      }
      if (!emailIsvalid) {
        return alert(
          'Campo E-mail é invalido, preencha o campo e-mail corretamente.'
        );
      }

      setLoadingButton(true);
      if (currentAccount.status !== status) {
        await updateUserStatus(documentNumber, status);
      }
      if (
        parseInt(ddd, 10) === currentAccount.ddd &&
        parseInt(ddi, 10) === currentAccount.ddi &&
        email === currentAccount.email &&
        nomeUsuario === currentAccount.nomeUsuario &&
        parseInt(numeroCelular.split('-').join(''), 10) ===
          currentAccount.numCelular
      ) {
        alert('Status atualizado com sucesso!');

        return setLoadingButton(false);
      }
      await patchUserAccount(documentNumber, {
        ddd: parseInt(ddd, 10),
        ddi: parseInt(ddi, 10),
        email,
        nomeUsuario,
        numeroCelular: parseInt(numeroCelular.split('-').join(''), 10),
        listaPerfis: [''],
      });
      await getClients();
      setLoadingButton(false);
      setEdit(false);
      alert('Informações do usuário atualizadas com sucesso!');
    } catch (error) {
      setLoadingButton(false);
      alert('Ocorreu um erro ao atualizar as informações do usuário.');
    }
  };

  const handleSetFields = (user: any) => {
    setCurrentAccount(user);
    setDdd(user.ddd);
    setDdi(user.ddi);
    setNumeroCelular(PhoneMask(JSON.stringify(user.numCelular)));
    setListaPerfis(user.perfis);
    setNomeUsuario(user.nomeUsuario);
    setEmail(user.email);
    setDocumentNumber(user.numeroDocumento);
    setEdit(true);
    setStatus(user.status);
  };
  const handleNumeroCelular = (value: string) => {
    setNumeroCelular(PhoneMask(value));
  };

  const handleDeleteUser = async (document: string) => {
    try {
      await deleteUser(document);
      await getClients();
      alert('Usuário excluido com sucesso!');
    } catch (error) {
      alert("Não' possível deletar um usuário 'ATIVO'!");
    }
  };

  const handlers = {
    userAccounts,
    ddd,
    setDdd,
    ddi,
    setDdi,
    numeroCelular,
    setNumeroCelular,
    nomeUsuario,
    setNomeUsuario,
    listaPerfis,
    setListaPerfis,
    setEdit,
    edit,
    handleSetFields,
    email,
    setEmail,
    status,
    setStatus,
    loadingButton,
    handleUpdateFields,
    handleNumeroCelular,
    handleDeleteUser,
  };

  return (
    <>
      {loading ? (
        <Loading size={40} icon={true} />
      ) : (
        <ManageUserScreen handlers={handlers} />
      )}
    </>
  );
}

export default ManageUserController;
