import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  @font-face {
        font-family: "Nexa-Bold";
        src: url('/assets/fonts/NexaBold.otf')
            format("opentype");
    }

  @font-face {
    font-family: "GothamLight";
        src: url("https://anima-uploads.s3.amazonaws.com/5b3c7db95d6b6a00096731e2/GOTHAM-LIGHT.TTF") format("None");
      }

      @font-face {
        font-family: "Nexa-Light";
        src: url("/assets/fonts/NexaLight.otf") format("opentype");
      }

      @font-face {
        font-family: "Nexa-XBold";
        src: url("/assets/fonts/NexaXBold.otf") format("opentype");
      }

      @font-face {
        font-family: "Gotham-Medium";
        src: url("/assets/fonts/Gotham-Medium.otf") format("opentype");
      }

     @font-face {
       font-family: "Arial-Regular";
        src: url("/assets/fonts/Arial-Regular.otf") format("opentype");
      }

      @font-face {
        font-family: "Nexa-Heavy";
        src: url('/assets/fonts/NexaHeavy.otf')
          format("opentype");
      }

      @font-face {
        font-family: "Nexa-Black";
        src: url("/assets/fonts/NexaBlack.otf")
          format("opentype");
      }
      @font-face {
        font-family: "Helvetica-Normal";
        src: url("/assets/fonts/HelveticaNeueLTStd-45Light.otf")
          format("opentype");
      }
      @font-face {
        font-family: "Helvetica-Medium";
        src: url("/assets/fonts/HelveticaNeueLTStd-65Medium.otf")
          format("opentype");
      }
      @font-face {
        font-family: "Helvetica-Bold";
        src: url("/assets/fonts/HelveticaNeueLTStd-75Bold.otf")
          format("opentype");
      }
      
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;

}

body, #root {
    max-height : 100vh;
    max-width: 100vw;
    font-size: 1.6rem;
}

button {
  outline: 0;
}


html{ 
    background: var (--white);
    font-size: 62.5%;
}
:root {

--black: #000;
--transparant-black: rgba(86, 96, 104, 1);
--transparant-black-00: rgba(0, 0, 0, 0.22);
--transparant-black-01: rgba(0, 0, 0, 0.08);
--transparant-black-02: rgba(0, 0, 0, 0.13);
--transparant-black-03: hsla(0, 0%, 0%, 0.16);
--dark-grey: #2C2C2C;
--soft-gray: #A1A5BC;
--gray-001: #566068;
--gray-002: #707070;
--gray-003: #A2A5A8;
--gray-005: #D3D3D3;
--gray-006: #DFE0E6;
--gray-007: #F5F6FA;
--gray-008: #EEEFF6;
--gray-009:#f2f2f2;
--white: #FFFFFF;
--primary:#00A4A1;
--primary-01: #039491;
--primary-02: #07b0ab;
--primary-03: #00ccc9;
--secondary: #f8f8f8;
--green: #2DCD7A;
--red: #D82445;
}
`;
