import React, { useEffect, useState } from 'react';
import PasswordUnlockScreen from './PasswordUnlock.screen';
import { postPasswordUnlock } from 'store/modules/companyCards/companyCards.endpoints';
import { connect } from 'react-redux';

interface IUser {
  id: number;
}
interface ICurrentCard {
  last4Digits: string;
  uuid: string;
}

interface IConsultCard {
  activeFlag: string;
  blockCode: string;
}

function PasswordUnlockController({
  user,
  currentCard,
  consultCard,
}: {
  user: IUser;
  currentCard: ICurrentCard;
  consultCard: IConsultCard;
}) {
  const [userId, setUserId] = useState(user.id);
  const [permanentBlock, setPermanentBlock] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (
      (consultCard.activeFlag === 'S' && consultCard.blockCode) ||
      consultCard.activeFlag === 'N'
    ) {
      setPermanentBlock(true);
      return setLoading(false);
    }
  }, []);

  const handlePasswordUnlock = async () => {
    try {
      const { last4Digits, uuid } = currentCard;
      const maxLength = 9;
      const diff = maxLength - String(userId).length;

      let zerosLeft = '';
      for (let index = 0; index < diff; index++) {
        zerosLeft = zerosLeft + '0';
      }
      const userIdFormated = zerosLeft + userId;
      const body = {
        last4digits: last4Digits,
        userId: userIdFormated,
      };
      setLoading(true);
      await postPasswordUnlock(uuid, body);
      alert('Senha desbloqueada com sucesso!');
      setLoading(false);
    } catch (error) {
      alert('Ocorreu um erro ao desbloquear a senha do cartão.');
      setLoading(false);
    }
  };

  const handlers = {
    handlePasswordUnlock,
    loading,
    permanentBlock,
  };

  return <PasswordUnlockScreen handlers={handlers} />;
}

const mapStateToProps = (state: any) => ({
  user: state.authenticate.user,
  currentCard: state.relationship.currentCard,
  consultCard: state.companyCards.consultCard,
});
export default connect(mapStateToProps)(PasswordUnlockController);
