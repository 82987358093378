import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  flex: 1;
  flex-direction: column;
`;

export const Blocked = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  font-family: 'Nexa-Heavy';
  color: var(--gray-001);
  padding: 20px 0;
`;

export const Title = styled.h3`
  font-family: 'Nexa-Heavy';
  color: var(--gray-001);
  margin-bottom: 30px;
`;

export const Label = styled.label`
  font-family: 'Helvetica-Normal';
  color: var(--gray-001);
  margin-bottom: 30px;
`;
export const Row = styled.div`
  flex-direction: row;
  margin-bottom: 5px;
`;
export const InputRadio = styled.input`
  margin-right: 20px;
`;

export const InputLabel = styled.label`
  font-family: Helvetica-Medium;
  color: var(--gray-001);
`;
export const Button = styled.button`
  width: 60%;
  height: 40px;
  border-radius: 30px;
  background-color: #00a4a1;
  border: none;
  color: #fff;
  cursor: pointer;
  font-family: 'Helvetica-Normal';

  &:hover {
    background-color: #00a4a1;
  }
  &:active {
    background-color: #00ccc9;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;
