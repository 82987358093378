import requester from '../requester';

const serviceRelationship = {
  invoices: {
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: '/v2/invoice/corporate',
    headers: {
      'Content-Type': 'application/json',
      environmentName: process.env.REACT_APP_ENVIRONMENT_NAME,
      systemName: process.env.REACT_APP_SYSTEM_NAME,
      org: process.env.REACT_APP_ORG_NUMBER,
      dueDate: '',
      cnpj: '{{cnpj}}',
      Authorization: '{{token}}',
    },
    method: 'get',
  },
  invoiceDueDate: {
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: '/v2/invoice/duedate/{{uuid}}',
    headers: {
      'Content-Type': 'application/json',
      environmentName: process.env.REACT_APP_ENVIRONMENT_NAME,
      systemName: process.env.REACT_APP_SYSTEM_NAME,
      last4Digits: '{{last4Digits}}',
      Authorization: '{{token}}',
    },
    method: 'get',
  },
};

function getToken() {
  const authenticateJson = localStorage.getItem('persist:Fidis:');
  const authenticate = JSON.parse(authenticateJson || '');
  const { token } = JSON.parse(authenticate.authenticate || '');

  return token;
}

export async function getInvoices(dueDate: string, companyCnpj: string) {
  let { invoices }: { invoices: any } = serviceRelationship;
  invoices.headers.Authorization = getToken();
  invoices.headers.cnpj = companyCnpj;
  invoices.headers.dueDate = dueDate;

  const response = await requester(invoices);
  return response;
}
export async function getInvoicesDueDate(last4Digits: string, uuid: string) {
  let { invoiceDueDate }: { invoiceDueDate: any } = serviceRelationship;
  invoiceDueDate.headers.Authorization = getToken();
  invoiceDueDate.headers.last4Digits = last4Digits;

  const endpoint = invoiceDueDate.endpoint.replace('{{uuid}}', uuid);

  const headers = { ...invoiceDueDate, endpoint };

  const response = await requester(headers);

  return response;
}
