import axios from 'axios';

function getToken() {
  const authenticateJson = localStorage.getItem('persist:Fidis:');
  const authenticate = JSON.parse(authenticateJson || '');
  const token = `Bearer ${authenticate?.tokenBackEnd?.OAuth2AccessToken?.access_token}`;

  return token;
}

export const sendOccurrence = async (
  cardId: string,
  last4digits: string,
  modalityType: string,
  openingText: string
) => {
  try {
    var data = JSON.stringify({
      last4digits,
      modalityType,
      openingText,
    });

    const headers = {
      'Content-Type': 'application/json',
      environmentName: process.env.REACT_APP_ENVIRONMENT_NAME,
      systemName: process.env.REACT_APP_SYSTEM_NAME,
      Authorization: getToken(),
    };

    const response = await axios({
      method: 'post',
      url: `https://api-hml.orbitallcartoes.com.br/v1/occurrence/registration/${cardId}`,
      headers,
      data,
    });

    return response;
  } catch (error) {
    return error.response;
  }
};
