import styled from 'styled-components';

export const SideMain = styled.div`
  display: flex;
  top: 0px;
  position: fixed;
  background: var(--white);
  flex-direction: column;
  height: auto;
  min-height: 90%;
  max-height: 100%;
  width: 18%;
  border-radius: 24px;
  box-shadow: 2px 2px 5px var(--soft-gray);
  margin-top: 10px;
  padding: 0 10px 0 0;

  @media (max-width: 1200px) {
    height: 90%;
  }
`;
export const MenuIcon = styled.img`
  height: auto;
  width: 90%;
  padding: 20px 0;

  @media (max-height: 768px) {
    width: 80%;
  }
`;
export const SideIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55px;
  height: 40px;
  background: var(--primary);
  border-radius: 0 15px 15px 0px;
`;
export const SideCardItem = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
`;
export const SearchIcon = styled.img`
  width: auto;
  height: 18px;
`;
export const Header = styled.div`
  flex: 3;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Body = styled.div`
  flex: 8;
`;

export const BodyLeft = styled.div`
  flex: 1;
`;
export const BodyRight = styled.div`
  display: flex;
  flex: 5;
  padding-left: 10px;
`;

export const Company = styled.div`
  margin-left: 5px;
  margin-right: 5px;
  border-top: 1px solid var(--gray-008);
  padding: 20px;
`;

export const Footer = styled.div`
  border-top: 1px solid var(--gray-008);
  flex: 2;
  display: flex;
  flex-direction: row;
  margin-left: 5px;
  margin-right: 5px;
`;

export const Label = styled.label`
  font-size: 16px;
  font-family: 'Nexa-XBold';
  cursor: pointer;
  text-align: left;

  @media (max-height: 768px) {
    font-size: 14px;
  }
  @media (max-width: 1400px) {
    font-size: 14px;
  }
  @media (max-width: 992px) {
    font-size: 12px;
  }
`;

export const Left = styled.div`
  flex: 2;
`;

export const Right = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 20px;
`;

export const LabelGrayMedium = styled.h5`
  color: var(--gray-003);
  font-size: 12px;
  font-family: 'Helvetica-Medium';
  text-align: left;
  padding: 10px 0;
`;

export const LabelGray = styled.h4`
  color: var(--gray-003);
  font-size: 14px;
  font-family: 'Helvetica-Medium';
  text-align: left;
`;

export const LabelGrayLight = styled.h5`
  color: var(--gray-003);
  font-size: 14px;
  font-family: 'Helvetica-Normal';
  text-align: left;
  padding: 5px 0;
`;

export const Button = styled.button`
  padding-top: 2px;
  height: 40px;
  width: 100%;
  border: 1px solid var(--primary);
  border-radius: 20px;
  font-family: 'Helvetica-Normal';
  color: var(--primary);
  background-color: transparent;
  cursor: pointer;
`;
export const Center = styled.div`
  padding: 10px 0;
  display: flex;
  justify-content: center;
  width: 100%;
`;
