import React from 'react';
import {
  Container,
  Blocked,
  Title,
  Label,
  Row,
  InputRadio,
  InputLabel,
  Button,
} from './deactiveCard.styles';

const deactiveCard = ({ handlers }: { handlers: any }) => {
  const {
    setBlockingReason,
    currentCard,
    blocked,
    handleActivation,
    handleDeactivation,
    permanentBlock,
  } = handlers;

  return (
    <Container>
      {permanentBlock ? (
        <Blocked>
          <Title>Cartão bloqueado.</Title>
        </Blocked>
      ) : (
        <>
          {blocked ? (
            <>
              <Title>Desbloqueio de cartão</Title>
              <Label>
                Deseja realmente desbloquear o cartão com final{' '}
                {currentCard?.last4Digits}?
              </Label>

              <Button onClick={handleActivation}>Confirmar Desbloqueio</Button>
            </>
          ) : (
            <>
              <Title>Bloqueio de cartão</Title>
              <Label>
                Qual o motivo do bloqueio do cartão final{' '}
                {currentCard?.last4Digits}?
              </Label>

              <Row>
                <InputRadio
                  type="radio"
                  onChange={(e) => setBlockingReason(e.target.value)}
                  id="reason"
                  name="reason"
                  value="P"
                />
                <InputLabel>Perda</InputLabel>
              </Row>
              <Row>
                <InputRadio
                  type="radio"
                  onChange={(e) => setBlockingReason(e.target.value)}
                  id="reason"
                  name="reason"
                  value="R"
                />
                <InputLabel>Roubo</InputLabel>
              </Row>
              <Row>
                <InputRadio
                  type="radio"
                  onChange={(e) => setBlockingReason(e.target.value)}
                  id="reason"
                  name="reason"
                  value="BE"
                />
                <InputLabel>Bloqueio temporário</InputLabel>
              </Row>
              <Row style={{ marginBottom: '40px' }}>
                <InputRadio
                  type="radio"
                  onChange={(e) => setBlockingReason(e.target.value)}
                  id="reason"
                  name="reason"
                  value="O"
                />
                <InputLabel>Outros</InputLabel>
              </Row>
              <Button onClick={handleDeactivation}>Confirmar Bloqueio</Button>
            </>
          )}
        </>
      )}
    </Container>
  );
};

export default deactiveCard;
