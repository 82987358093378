import React from 'react'
import { Button } from './styles'

function ButtonComponent({
    children,
    onClick,
}: {
    children: string;
    onClick?: any;
}) {
    return <Button onClick={onClick}>{children}</Button>
}

export default ButtonComponent
