import React from 'react'
import { Container, Spinner, LogoIcon } from './Loading.styles'
import ovalIcon from '../../assets/icons/loading.png'
import logoIcon from '../../assets/images/logoIcon.png'

type Size = {
    size: 'small' | 'medium' | 'large' | 'extra-large'
}

function LoadingComponent({ size, icon }: { size: Number; icon?: boolean }) {
    return (
        <Container color={'#000'}>
            <Spinner size={size} src={ovalIcon} className="loading" />
            {icon && <LogoIcon size={size} src={logoIcon} />}
        </Container>
    )
}

export default LoadingComponent
