import styled from 'styled-components'

export const Container = styled.div<any>`
color: white;
border-radius: 10px;
position: absolute;
top: 50px;
right: 10px;
padding: 20px;
display: flex;
align-items: center;
z-index: 1111;
background:${(props) => props.type};
`

export const Close = styled.span`
color: white;
cursor: pointer;
margin-right: 10px;
`