import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const TitleArea = styled.div`
  padding: 30px 0;
  @media (max-width: 576px) {
    display: flex;
    justify-content: center;
    padding: 0;
  }
`;

export const Title = styled.h3`
  font-family: 'Nexa-Heavy';
  color: var(--gray-001);
`;

export const Label = styled.label`
  font-family: 'Helvetica-Normal';
  color: var(--gray-001);
  margin-bottom: 30px;
`;
export const Button = styled.button`
  width: 60%;
  height: 40px;
  border-radius: 30px;
  background-color: var(--primary);
  border: none;
  color: #fff;
  &:hover {
    background-color: var(--primary);
  }
  &:active {
    background-color: var(--primary-03);
  }
`;

export const Left = styled.div`
  flex: 3;
`;
export const Right = styled.div`
  flex: 2;
`;
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;
