import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const Title = styled.h3`
  font-family: Nexa-XBold;
  color: var(--gray-001);
  margin-bottom: 20px;
  margin-top: 15px;
`;

export const Label = styled.label`
  font-family: 'Helvetica-Normal';
  color: var(--gray-001);
  font-size: 16px;
  padding: 10px 0;
`;
export const LabelMedium = styled.label`
  font-family: 'Helvetica-Medium';
  color: var(--gray-001);
  font-size: 14px;
`;

export const Content = styled.div`
  background-color: var(--white);
  padding-top: 20px;
  padding-left: 40px;
  padding-right: 40px;
  border-radius: 4px;
  border-width: 0px;
  padding-bottom: 40px;
`;
export const ListGroup = styled.div`
  background-color: var(--white);
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 10px;
`;
export const Line = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--gray-009);
  margin-top: 10px;
`;
export const Divider = styled.div`
  padding: 20px;
`;
