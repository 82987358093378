export const DocumentMask = (text: string) => {
  text = text.replace(/\D/g, '');
  text = text.replace(/(\d{3})(\d)/, '$1.$2');
  text = text.replace(/(\d{3})(\d)/, '$1.$2');
  text = text.replace(/(\d{3})(\d{1,2})$/, '$1-$2');

  return text;
};

export const maskCPFeCNPJ = (number: string) => {
  number = number.replace(/\D/g, '');
  if (number.length > 11) {
    number = number.replace(/^(\d{2})(\d)/, '$1.$2');
    number = number.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
    number = number.replace(/\.(\d{3})(\d)/, '.$1/$2');
    number = number.replace(/(\d{4})(\d)/, '$1-$2');
  } else {
    number = number.replace(/\D/g, '');
    number = number.replace(/(\d{3})(\d)/, '$1.$2');
    number = number.replace(/(\d{3})(\d)/, '$1.$2');
    number = number.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
  }
  return number;
};

export const cleanDocument = (value: string) => {
  let document = value.replace(/\./g, '');
  document = document.replace(/-/g, '');
  document = document.replace(/\//g, '');
  return document;
};
