import React from 'react';
import {
  Container,
  Left,
  Right,
  Divider,
  Middle,
  Title,
} from './RequestCard.styles';
import SelectCard from './components/SelectCard/SelectCard.component';
import Form from './components/Form/Form.component';

function RequestCardScreen({ handlers }: { handlers: any }) {
  let { creditCard } = handlers;

  return (
    <Container>
      <Left>
        <Title style={{ color: creditCard ? '#D3D3D3' : '#566068' }}>
          1 - Escolha o produto
        </Title>
        <SelectCard handlers={handlers} />
      </Left>
      <Middle>{creditCard && <Divider />}</Middle>
      <Right>
        {creditCard && (
          <>
            <Title>2 - Preencha os dados do portador</Title>
            <Form handlers={handlers} />
          </>
        )}
      </Right>
    </Container>
  );
}

export default RequestCardScreen;
