import React, { useEffect, useState } from 'react'
import { Container, Close } from './Flash.styles'
import Bus from '../../utils/Bus'

function Flash() {
    let [visibility, setVisibility] = useState(false)
    let [message, setMessage] = useState('')
    let [type, setType] = useState('')

    useEffect(() => {
        Bus.addListener('flash', ({ message, type }) => {
            setVisibility(true)
            setMessage(message)
            setType(type)
            setTimeout(() => {
                setVisibility(false)
            }, 4000)
        })
    }, [])

    // useEffect(() => {
    //     if(document.querySelector('.close') !== null) {
    //         document.
    //         querySelector('.close').
    //         addEventListener('click', () => setVisibility(false));
    //     }
    // })

    return (
        <>
            {visibility && (
                <Container type={type}>
                    <Close>
                        <strong>X</strong>
                    </Close>
                    <p>{message}</p>
                </Container>
            )}
        </>
    )
}

export default Flash
