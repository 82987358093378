import styled, { createGlobalStyle, css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
  align-content: center;
  padding: 2px;
  margin-bottom: 15px;
`;

export const Label = styled.h5`
  font-family: 'Helvetica-Medium';
  color: var(--gray-001);
  font-size: 14px;
  text-align: center;
`;

export const LabelHistory = styled(Label)`
  font-size: 16px;
`;

export const Title = styled.h5`
  font-family: 'Nexa-Bold';
  color: var(--gray-001);
  font-size: 1em;
  text-align: center;
  color: var(--gray-001);
`;

export const Header = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Separator = styled.div`
  display: flex;
  flex: 1;
  margin-right: 1px;
  justify-content: center;
  align-items: center;
  min-height: 50px;
  /* padding: 0 10px; */
`;

export const CategoryHistory = styled(Separator)`
  justify-content: flex-start;
`;

export const Button = styled.button`
  width: 100%;
  height: 40px;
  border-radius: 20px;
  background-color: #fff;
  color: var(--gray-001);
  border-width: 1px;
  border-color: var(--gray-001);
  font-family: 'Helvetica-Medium';
  cursor: pointer;
  &:hover {
    // background-color: #00a4a1;
  }
  &:active {
    // background-color: #00ccc9;
  }
`;
