import Loading from 'components/Loading/Loading.component';
import Header from 'components/NavigationHeader';
import { Container, DivLine, Unavailable } from './Invoices.styles';
import DueDate from './components/DueDate/DueDate.components';
import InvoiceComponent from './components/Invoice/Invoice.components';

function InvoicesScreen({ handlers }: { handlers: any }) {
  const { emptyInvoice, loader } = handlers;

  const Content = () => {
    if (loader) return <Loading size={40} icon={true} />

    if (emptyInvoice) return <Unavailable>Fatura indisponível</Unavailable>

    return (
      <>
        <DueDate handlers={handlers} />
        <DivLine />
        <InvoiceComponent handlers={handlers} />
      </>
    )
  }

  return (
    <Container>
      <Header />
      <Content />
    </Container>
  );
}

export default InvoicesScreen;
