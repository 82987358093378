export const setDataAccounts = (user: any) => {
  return { type: '@authenticate/SET_DATA_ACCOUNTS', user };
};

export const setToken = (token: string) => {
  return { type: '@authenticate/SET_TOKEN', token };
};
export const signOut = () => {
  return { type: '@authenticate/SIGN_OUT' };
};
