import produce from 'immer';
export const INITIAL_STATE = {
  user: null,
  token: null,
};

export default function AuthenticateReducer(
  state = INITIAL_STATE,
  action: any
) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@authenticate/SET_DATA_ACCOUNTS': {
        draft.user = action.user;
        break;
      }
      case '@authenticate/SET_TOKEN': {
        draft.token = action.token;
        break;
      }
      case '@authenticate/SIGN_OUT': {
        draft.token = null;
        break;
      }
      default:
    }
  });
}
