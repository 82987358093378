import styled from 'styled-components';

export const Container = styled.div`
  height: 50px;
  width: 100%;
  background-color: transparent;
  display: flex;
  align-items: left;
  flex-direction: column;
  margin-bottom: 15px;
`;

export const InputSelect = styled.select`
  border-color: transparent;
  border-width: 0px;
  border: none;
  height: 45px;
  width: 100%;
  outline: none;
  color: var(--gray-001);
  border-radius: 25px;
  padding: 0 10px;
`;

export const Title = styled.label`
  padding-left: 5px;
  color: var(--gray-001);
  font-size: 1.4rem;
  margin-bottom: 4px;
  font-family: 'Helvetica Neue LT Std', Helvetica, Arial, serif;
`;
