import React from 'react';
import {
  Container,
  Row,
  Left,
  Right,
  Button,
  ButtonOutline,
  LeftInput,
  RightInput,
  MiddleInput,
} from './Fields.styles';
import InputLabel from 'components/InputLabel';
import Loading from 'components/Loading/Loading.component';
import Select from 'components/InputSelect/InputSelect.component';

function FieldsComponent({ handlers }: { handlers: any }) {
  const {
    ddd,
    setDdd,
    ddi,
    setDdi,
    numeroCelular,
    setNumeroCelular,
    nomeUsuario,
    setNomeUsuario,
    listaPerfis,
    setListaPerfis,
    setEdit,
    email,
    setEmail,
    status,
    setStatus,
    loadingButton,
    handleUpdateFields,
    handleNumeroCelular,
  } = handlers;
  const optionsStatus = [
    { title: 'ATIVO', value: 'ATIVO' },
    { title: 'INATIVO', value: 'INATIVO' },
  ];

  return (
    <Container>
      <Row>
        <Left>
          <InputLabel
            value={nomeUsuario}
            onChange={setNomeUsuario}
            label="Nome do Usuário"
            type="text"
          />
          <InputLabel
            value={email}
            onChange={setEmail}
            label="E-mail"
            type="text"
          />
          <Row>
            <LeftInput>
              <InputLabel
                value={ddi}
                onChange={setDdi}
                label="DDI"
                type="text"
                maxLength={2}
              />
            </LeftInput>
            <MiddleInput>
              <InputLabel
                value={ddd}
                onChange={setDdd}
                label="DDD"
                type="text"
                maxLength={2}
              />
            </MiddleInput>
            <RightInput>
              <InputLabel
                value={numeroCelular}
                onChange={handleNumeroCelular}
                label="Celular"
                type="text"
                maxLength={10}
              />
            </RightInput>
          </Row>
          <RightInput>
            <Select
              defaultValue={status}
              onChange={setStatus}
              label="Status"
              options={optionsStatus}
            />
          </RightInput>
          {loadingButton ? (
            <Loading size={20} />
          ) : (
            <>
              <Button
                onClick={() => handleUpdateFields()}
                style={{ marginBottom: 10 }}
              >
                Salvar Alterações
              </Button>
              <ButtonOutline onClick={() => setEdit(false)}>
                Cancelar
              </ButtonOutline>
            </>
          )}
        </Left>
        <Right />
      </Row>
    </Container>
  );
}

export default FieldsComponent;
