import React from 'react';
import {
  Container,
  Title,
  Description,
  Top,
  Middle,
  InputArea,
  Bottom,
  Button,
  Label,
} from './ForgotPassword.styles';
import InputIcon from '../../components/InputIcon';
import userIcon from '../../assets/icons/user.png';
import lockIcon from '../../assets/icons/lock.png';
import Loading from 'components/Loading/Loading.component';
import { Link } from 'react-router-dom';

const ForgotPasswordScreen = ({ handlers }: { handlers: any }) => {
  const {
    handleSendCode,
    username,
    code,
    setUsername,
    setCode,
    loading,
    codeValidated,
    showConfirmation,
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    handleSwitchFunctions,
  } = handlers;

  const description = `Preencha o campo abaixo com seu Usuário para receber as instruções.`;

  return (
    <Container>
      <Top>
        <Title>Recuperar senha</Title>
        <Description>{description}</Description>
      </Top>
      <Middle>
        {!codeValidated ? (
          <InputArea>
            <InputIcon
              value={username}
              onChange={setUsername}
              icon={userIcon}
              label="Usuário"
              placeholder="Digite o usuário"
            />
            {showConfirmation && (
              <InputIcon
                value={code}
                onChange={setCode}
                icon={lockIcon}
                label="Código de Validação"
              />
            )}
          </InputArea>
        ) : (
          <>
            <InputIcon
              value={password}
              onChange={setPassword}
              icon={lockIcon}
              label="Senha"
              type="password"
            />
            <InputIcon
              value={confirmPassword}
              onChange={setConfirmPassword}
              icon={lockIcon}
              label="Confirmar senha"
              type="password"
            />
          </>
        )}
      </Middle>
      <Bottom>
        {loading ? (
          <Loading size={25} />
        ) : (
          <>
            <Button onClick={handleSwitchFunctions}>CONFIRMAR</Button>
            <Link
              style={{
                textDecoration: 'none',
                color: 'var(--primary);',
                textAlign: 'center',
                marginTop: '5px',
              }}
              to="/"
            >
              <Label>Voltar</Label>
            </Link>
          </>
        )}
      </Bottom>
    </Container>
  );
};

export default ForgotPasswordScreen;
