import React from 'react';
import { Container, InputSelect, Title } from './Select.styles';

function InputSelectComponent({
  placeholder,
  label,
  onChange,
  value,
  options,
  styles,
  backgroundColor,
  defaultLabel,
}: {
  placeholder: string;
  label?: string;
  onChange?: any;
  value?: string;
  options?: any;
  styles?: any;
  backgroundColor?: any;
  defaultLabel?: any;
}) {
  return (
    <Container style={styles}>
      <Title>{label}</Title>
      <InputSelect
        style={{ backgroundColor: backgroundColor }}
        value={value}
        placeholder={placeholder}
        onChange={(e: any) => onChange(e.target.value)}
      >
        {defaultLabel && (
          <option value="" selected disabled>
            {defaultLabel}
          </option>
        )}
        {options?.map((item: any, index: string) => {
          const valueJSON = JSON.stringify(item);
          return (
            <option key={index} value={valueJSON}>
              {item?.title}
            </option>
          );
        })}
      </InputSelect>
    </Container>
  );
}

export default InputSelectComponent;
