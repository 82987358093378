import React from 'react';
import {
  Container,
  Blocked,
  Title,
  Label,
  Button,
  Box,
  CurrentLimit,
  Left,
  Right,
  Icon,
  Form,
  AmountLabel,
} from './ManageLimit.styles';
import creditCard from '../../assets/icons/creditCard.png';
import InputLabel from '../../components/InputLabel';
import { MoneyMask } from 'utils/Masks/MoneyMask';

function ManageLimitScreen({ handlers }: { handlers: any }) {
  const {
    currentLimit,
    handleLimit,
    setLimit,
    limit,
    creditUsed,
    permanentBlock,
  } = handlers;

  function handleCreditLimit(text: string) {
    setLimit(MoneyMask(text));
  }

  const formatMoney = () => {
    if (currentLimit?.availableLimit || currentLimit?.availableLimit >= 0) {
      return parseFloat(currentLimit?.availableLimit).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      });
    }
    return '-';
  };

  return (
    <Container>
      {permanentBlock ? (
        <Blocked>Limite indisponível - Cartão bloqueado</Blocked>
      ) : (
        <>
          <Title>Gestão de limites</Title>
          <Box>
            <CurrentLimit>
              <Left>
                <Icon src={creditCard} />
              </Left>
              <Right>
                <Label>Limite atual</Label>

                <AmountLabel>{formatMoney()}</AmountLabel>

                {creditUsed ? (
                  <Label>Utilizado: {creditUsed}</Label>
                ) : (
                  <Label> - </Label>
                )}
              </Right>
            </CurrentLimit>
            <Form>
              <InputLabel
                value={limit ? `R$${limit}` : limit}
                onChange={(text: string) => handleCreditLimit(text)}
                label="Novo Limite"
                placeholder="Acrescente um novo limite"
              />
              <Button onClick={() => handleLimit()}>Salvar Alterações</Button>
            </Form>
          </Box>
        </>
      )}
    </Container>
  );
}

export default ManageLimitScreen;
