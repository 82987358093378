import React from 'react';
import {
  Container,
  Title,
  Label,
  LabelMedium,
  Content,
  ListGroup,
  Line,
  Divider,
} from './Invoice.styles';
import moment from 'moment';
import 'moment/locale/pt-br';

import { MoneyMask } from 'utils/Masks/MoneyMask';

function InvoiceComponent({ handlers }: { handlers: any }) {
  const {
    dueDate,
    totalInvoiceValue,
    digitableLine,
    previousInvoiceValue,
    totalPaymentValue,
    bsPaymentMinNotParcel,
    expenditureInternationalValue,
    totalDebitNationalValue,
    withdrawalCashLimitInternationalValue,
    withdrawalCashLimitNationalValue,
    emptyInvoice,
    financialBurdenValue,
    paidNotIntegralValue,
  } = handlers;

  return (
    <Container>
      {dueDate && (
        <Title>
          Fatura referente ao mês de {moment(dueDate).format('MMMM')} de{' '}
          {moment(dueDate).format('YYYY')}
        </Title>
      )}
      {totalInvoiceValue && (
        <Label>
          Valor total da fatura:{' '}
          <LabelMedium>R$ {MoneyMask(totalInvoiceValue)}</LabelMedium>
        </Label>
      )}
      {digitableLine && (
        <Label>
          Linha digitável: <LabelMedium>{digitableLine}</LabelMedium>
        </Label>
      )}
      <Divider />
      <Content>
        <Title>Saldo:</Title>
        <ListGroup>
          <Line>
            <LabelMedium>Saldo anterior:</LabelMedium>
            {previousInvoiceValue ? (
              <LabelMedium>R$ {MoneyMask(previousInvoiceValue)}</LabelMedium>
            ) : (
              <LabelMedium>-</LabelMedium>
            )}
          </Line>
          <Line>
            <LabelMedium>Pagamentos e Créditos:</LabelMedium>
            {totalPaymentValue ? (
              <LabelMedium>R$ {MoneyMask(totalPaymentValue)}</LabelMedium>
            ) : (
              <LabelMedium>-</LabelMedium>
            )}
          </Line>
          {/* <Line>
            <LabelMedium>Saldo Financiado:</LabelMedium>
            {previousInvoiceValue && totalPaymentValue ? (
              <LabelMedium>R$ {MoneyMask(financialBurdenValue)}</LabelMedium>
            ) : (
              <LabelMedium>-</LabelMedium>
            )}
          </Line> */}
          <Line>
            <LabelMedium>Encargos:</LabelMedium>
            <LabelMedium>-</LabelMedium>
          </Line>
          <Line>
            <LabelMedium>Saldo atual:</LabelMedium>
            {paidNotIntegralValue ? (
              <LabelMedium>R$ {MoneyMask(paidNotIntegralValue)}</LabelMedium>
            ) : (
              <LabelMedium>-</LabelMedium>
            )}
          </Line>
          {/* <Line>
            <LabelMedium>Mínimo da Fatura:</LabelMedium>
            {paidNotIntegralValue ? (
              <LabelMedium>R$ {MoneyMask(paidNotIntegralValue)}</LabelMedium>
            ) : (
              <LabelMedium>-</LabelMedium>
            )}
          </Line> */}
        </ListGroup>
        <Title>Despesas:</Title>
        <ListGroup>
          <Line>
            <LabelMedium>Nacional:</LabelMedium>
            {totalDebitNationalValue ? (
              <LabelMedium>R$ {MoneyMask(totalDebitNationalValue)}</LabelMedium>
            ) : (
              <LabelMedium>-</LabelMedium>
            )}
          </Line>
          <Line>
            <LabelMedium>Internacional:</LabelMedium>
            {expenditureInternationalValue ? (
              <LabelMedium>
                R$ {MoneyMask(expenditureInternationalValue)}
              </LabelMedium>
            ) : (
              <LabelMedium>-</LabelMedium>
            )}
          </Line>
        </ListGroup>
        <Title>Cash:</Title>
        <ListGroup>
          <Line>
            <LabelMedium>Nacional (R$):</LabelMedium>
            {withdrawalCashLimitNationalValue ? (
              <LabelMedium>
                R$ {MoneyMask(withdrawalCashLimitNationalValue)}
              </LabelMedium>
            ) : (
              <LabelMedium>-</LabelMedium>
            )}
          </Line>
          <Line>
            <LabelMedium>Internacional (R$):</LabelMedium>
            {withdrawalCashLimitInternationalValue ? (
              <LabelMedium>
                R$ {MoneyMask(withdrawalCashLimitInternationalValue)}
              </LabelMedium>
            ) : (
              <LabelMedium>-</LabelMedium>
            )}
          </Line>
        </ListGroup>
        <Title>Outros:</Title>
        <ListGroup>
          <Line>
            <LabelMedium>Débitos (R$):</LabelMedium>
            <LabelMedium>-</LabelMedium>
          </Line>
          <Line>
            <LabelMedium>Créditos (R$):</LabelMedium>
            <LabelMedium>-</LabelMedium>
          </Line>
          <Line>
            <LabelMedium>Tarifas (R$):</LabelMedium>
            <LabelMedium>-</LabelMedium>
          </Line>
        </ListGroup>
      </Content>
    </Container>
  );
}

export default InvoiceComponent;
