import React from 'react'
import { Container, Input, Title } from './styles'

//prettier-ignore
function InputIcon({
    icon,
    label,
    type,
    onChange,
    value,
    placeholder,
}: {
    icon: any;
    label: string;
    type?: string;
    onChange?: any;
    value?: string;
    placeholder?: string;
}) {
    return (
        <div style={{ marginTop: '10px' }}>
            <Title>{label}</Title>
            <Container>
                <img
                    height="50%"
                    width="10%"
                    style={{ paddingLeft: '4px', objectFit: 'contain' }}
                    src={icon}
                />
                <Input
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    type={type}
                    placeholder={placeholder}
                />
            </Container>
        </div>
    )
}

export default InputIcon
