import React from 'react';
import {
  Container,
  Label,
  LabelHistory,
  Header,
  Separator,
  CategoryHistory,
  Title,
} from './HistoryCard.styles';
import moment from 'moment';
import { ReactComponent as IconMoney } from '../../../../assets/svg/moneyIncome.svg';

function HistoryCard({ allCardsTransactions }: { allCardsTransactions: any }) {
  return allCardsTransactions.map(
    (cardTransaction: any) =>
      cardTransaction.register.length > 0 && (
        <>
          <CategoryHistory>
            <LabelHistory>
              {cardTransaction.card.cardType} - Cartão Final{' '}
              <u>{cardTransaction.card.last4digits}</u>
            </LabelHistory>
          </CategoryHistory>
          <Header>
            <Separator>
              <Title>Data / Hora</Title>
            </Separator>
            <Separator>
              <Title>Nº Autoriz.</Title>
            </Separator>
            <Separator>
              <Title>Estabelecimento</Title>
            </Separator>
            <Separator>
              <Title>Parcelas</Title>
            </Separator>
            <Separator>
              <Title>Valor</Title>
            </Separator>
            <Separator>
              <Title>Status</Title>
            </Separator>
          </Header>
          {cardTransaction.register.map((transaction: any) => (
            <Container>
              <Separator>
                <Label
                  style={{
                    color:
                      transaction.abbreviationGroup === 'CRE' ||
                      transaction.abbreviationGroup === 'PGT'
                        ? '#00A4A1'
                        : '#566068',
                  }}
                >
                  {moment.isMoment(transaction?.date) &&
                  transaction?.isAutorization
                    ? transaction?.date?.format('DD/MM/YYYY HH:mm:ss')
                    : transaction?.date?.format('DD/MM/YYYY')}
                </Label>
              </Separator>
              <Separator>
                <Label>{transaction.numAuthorization}</Label>
              </Separator>
              <Separator>
                <Label
                  style={{
                    color:
                      transaction.abbreviationGroup === 'CRE' ||
                      transaction.abbreviationGroup === 'PGT'
                        ? '#00A4A1'
                        : '#566068',
                  }}
                >
                  {transaction.establishmentName}
                </Label>
              </Separator>
              <Separator>
                <Label
                  style={{
                    color:
                      transaction.abbreviationGroup === 'CRE' ||
                      transaction.abbreviationGroup === 'PGT'
                        ? '#00A4A1'
                        : '#566068',
                  }}
                >
                  {transaction.qtdParcel}
                </Label>
              </Separator>
              <Separator>
                <Label
                  style={{
                    color:
                      transaction.abbreviationGroup === 'CRE' ||
                      transaction.abbreviationGroup === 'PGT'
                        ? '#00A4A1'
                        : '#566068',
                  }}
                >
                  {transaction.value}
                </Label>
              </Separator>
              <Separator>
                <Label
                  style={{
                    color:
                      transaction.abbreviationGroup === 'CRE' ||
                      transaction.abbreviationGroup === 'PGT'
                        ? '#00A4A1'
                        : '#566068',
                  }}
                >
                  {transaction.abbreviationGroup === 'CRE' ||
                  transaction.abbreviationGroup === 'PGT'
                    ? 'CRÉDITO/\nPAGAMENTO'
                    : transaction.status}
                </Label>
              </Separator>
            </Container>
          ))}
        </>
      )
  );
}

export default HistoryCard;
