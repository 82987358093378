import React from 'react';
import {
  Container,
  Label,
  Header,
  Separator,
  CardStatus,
  TextArea,
  IconArea,
  CardStatusMobile,
  Title,
  ButtonDetails,
  Icon,
  Row,
  ButtonTitle,
  Left,
  Right,
  Circular,
  PaginatiionArea,
  Pagination,
  Arrow,
  NumberPageArea,
  NumberPage,
  ArrowRight,
  ArrowLeft,
} from './ListCards.styles';
import moreIcon from '../../../../assets/icons/more.png';
import { DocumentMask } from '../../../../utils/Masks/DocumentMask';
import { connect } from 'react-redux';

type Props = {
  currentPage?: string;
};

function ListCardsComponent({
  handlers,
  numberPages,
  currentPage,
}: {
  handlers: any;
  numberPages: any;
  currentPage: number;
}) {
  const {
    handleDetails,
    handlePreviousPage,
    handleNextPage,
    accounts,
  } = handlers;
  const nextPage = accounts?.totalSubAccounts - accounts?.subAccounts?.length;

  return (
    <>
      <Header>
        <Separator>
          <Title>Portador</Title>
        </Separator>
        <Separator>
          <Title>CPF</Title>
        </Separator>
        <Separator>
          <Title>Final do Cartão</Title>
        </Separator>
        <Separator>
          <Title>Status</Title>
        </Separator>
        <Separator>
          <Title>Ver Detalhes</Title>
        </Separator>
      </Header>
      {accounts?.subAccounts?.map((card: any, index: string) => {
        return (
          <div key={index}>
            <ListCard card={card} handleDetails={handleDetails} />
          </div>
        );
      })}
      {nextPage > 0 && (
        <PaginatiionArea>
          <Pagination>
            {currentPage > 1 && (
              <Arrow onClick={() => handlePreviousPage()}>
                <ArrowLeft />
              </Arrow>
            )}
            <NumberPageArea>
              {numberPages.map(
                (numberPage: { page: string }, index: number) => {
                  if (index === 15) {
                    return '...';
                  }
                  if (index > 15 && index < 55) {
                    return;
                  }
                  return (
                    <NumberPage
                      key={numberPage.page}
                      currentPage={currentPage === index + 1}
                    >
                      {numberPage.page}
                    </NumberPage>
                  );
                }
              )}
            </NumberPageArea>
            {numberPages.length === currentPage ? (
              <></>
            ) : (
              <Arrow onClick={() => handleNextPage()}>
                <ArrowRight />
              </Arrow>
            )}
          </Pagination>
        </PaginatiionArea>
      )}
    </>
  );
}

function ListCard({ card, handleDetails }: { card: any; handleDetails: any }) {
  const borderRight = { borderRight: '1px solid #2C2C2C', height: '13px' };

  // const translaterStatus: any = {
  //   inactive: { label: 'Inativo', color: 'red' },
  //   active: { label: 'Ativo', color: '#2ECD7A' },
  // };

  // const status = translaterStatus[card.cardStatus];

  const verifyStatus = () => {
    if (
      card.cardStatus === 'active' &&
      (!card.cardBlockingCode || card.cardBlockingCode === '0') &&
      (!card.accountBlockingCodeTwo || card.accountBlockingCodeTwo === '0') &&
      (!card.accountWarningCode || card.accountWarningCode) === '0'
    ) {
      return { label: 'Ativo', color: '#2ECD7A' };
    }
    return { label: 'Inativo', color: 'red' };
  };

  const status = verifyStatus();

  return (
    <Container>
      <Separator style={borderRight}>
        <Label>{card?.cardHolder}</Label>
      </Separator>
      <Separator style={borderRight}>
        <Label>
          {card?.documentNumber.length > 10
            ? DocumentMask(card?.documentNumber)
            : DocumentMask(`0${card?.documentNumber}`)}
        </Label>
      </Separator>
      <Separator style={borderRight}>
        <Label>
          {window.innerWidth > 768 && '**** **** **** '}
          {card?.last4Digits2Way || card?.last4Digits}
        </Label>
      </Separator>
      <Separator style={borderRight}>
        <CardStatus>
          <TextArea>
            <Label>Cartão {status.label}</Label>
          </TextArea>
          <IconArea>
            <Circular style={{ backgroundColor: status.color }} />
          </IconArea>
        </CardStatus>
        <CardStatusMobile>
          <Circular style={{ backgroundColor: status.color }} />
        </CardStatusMobile>
      </Separator>
      <Separator>
        {window.innerWidth > 992 ? (
          <ButtonDetails onClick={() => handleDetails(card)}>
            <Row>
              <Left />
              <Left>
                <ButtonTitle>Detalhes</ButtonTitle>
              </Left>
              <Right>
                <Icon src={moreIcon} />
              </Right>
              <Left />
            </Row>
          </ButtonDetails>
        ) : (
          <Icon onClick={() => handleDetails(card)} src={moreIcon} />
        )}
      </Separator>
    </Container>
  );
}

const mapStateToProps = (state: any) => ({
  numberPages: state.relationship.numberPages,
  currentPage: state.relationship.currentPage,
});
export default connect(mapStateToProps)(ListCardsComponent);
