import styled from 'styled-components';
import arrowRight from 'assets/icons/right-arrow.png';
import arrowLeft from 'assets/icons/left-arrow.png';

export const Container = styled.div`
  display: flex;

  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
  align-content: center;
  padding: 2px;
  margin-bottom: 15px;
`;

export const Label = styled.label`
  font-family: 'Helvetica-Medium';
  font-size: 14px;
  text-align: center;
  color: var(--gray-001);

  @media (max-width: 576px) {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: -webkit-box;
    width: 60px;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  @media (max-width: 768px) {
    font-size: 0.7em;
  }
`;

export const Title = styled.h5`
  font-family: 'Nexa-Bold';
  font-size: 1em;
  text-align: center;
  color: var(--gray-001);
  @media (max-width: 768px) {
    font-size: 0.8em;
  }
`;

export const Header = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

export const Separator = styled.div`
  display: flex;
  flex: 1;
  margin-right: 1px;
  justify-content: center;
  align-items: center;
  height: 50px;
  flex-direction: row;

  @media (max-width: 768px) {
    padding-left: 5px;
    padding-right: 5px;
  }
`;

export const CardStatus = styled.div`
  display: flex;
  align-items: center;
  flex: 1;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const TextArea = styled.div`
  display: flex;
  flex: 2;
  justify-content: flex-end;
`;

export const IconArea = styled.div`
  display: flex;
  flex: 1;
`;

export const CardStatusMobile = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    display: flex;
  }
`;

export const ButtonDetails = styled.button`
  padding: 5px 10px;
  border-radius: 20px;
  background-color: #fff;
  border: 1px solid #566068;
  font-family: 'Helvetica-Medium';
  cursor: pointer;
  transition: all 0.6s;

  &:hover {
    box-shadow: 0px 2px 4px #00000050;
  }
`;

export const Icon = styled.img`
  height: 17px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 4px;
`;

export const ButtonTitle = styled.label`
  font-family: 'Helvetica-Medium';
  font-size: 14px;
  color: var(--gray-001);
  text-align: right;
  cursor: pointer;
`;

export const Left = styled.div`
  flex: 1;
  align-self: center;
`;

export const Middle = styled.div`
  flex: 3;
  align-self: center;
`;
export const Right = styled.div`
  flex: 1;
  align-self: center;
  margin-left: 10px;
`;

export const Circular = styled.div`
  height: 10px;
  width: 10px;
  border-radius: 5px;
  margin-left: 5px;
  margin-bottom: 4px;
  display: flex;
`;

export const PaginatiionArea = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
`;

export const Pagination = styled.div`
  display: flex;
  align-items: center;
`;

export const Arrow = styled.div`
  background: #fff;
  border-radius: 20px;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    box-shadow: 0px 2px 4px #00000050;
  }
`;

export const ArrowRight = styled.img.attrs({ src: arrowRight })`
  width: 12px;
  height: 12px;
  color: #fff;
`;
export const ArrowLeft = styled.img.attrs({ src: arrowLeft })`
  width: 12px;
  height: 12px;
  color: #fff;
`;

export const NumberPageArea = styled.div`
  display: flex;
  background: #fff;
  height: 40px;
  border-radius: 20px;
  align-items: center;
`;

type Props = {
  currentPage?: boolean;
};

export const NumberPage = styled.div<Props>`
  background: ${(props) => (props.currentPage ? 'var(--primary)' : '#fff')};
  color: ${(props) => (props.currentPage ? '#fff' : '#000')};
  font-family: 'Helvetica-Medium';
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  width: 40px;
  height: 40px;
  font-size: 14px;
  transition: all 0.2s;
`;
