import React from 'react'
import { Container, InputSelect, Title } from './InputSelect.styles'

type Option = {
    title?:string;
    value?:string;
}

function InputSelectComponent({
    placeholder,
    label,
    onChange,
    value,
    options,
    styles,
    backgroundColor,
    defaultValue
}: {
    placeholder?: string;
    label?: string;
    onChange?: any;
    value?: string;
    options?:any;
    styles?:any;
    backgroundColor?:any;
    defaultValue?:string;
}) {
    return (
        <Container style={styles}>
            <Title>{label}</Title>

            <InputSelect
            style={{backgroundColor:backgroundColor}}
                value={value}
                placeholder={placeholder}
                onChange={(e) => onChange(e.target.value)}
            >
                {defaultValue && (
                    <option value={defaultValue} selected disabled >
                        {defaultValue}
                    </option>
                )}
                {options?.map((item:Option,index:string)=>{
                    return(
                        <option key={index} value={item?.value}>{item?.title}</option>
                    )
                })}
            </InputSelect>
        </Container>
    )
}

export default InputSelectComponent
