import React, { useState, useEffect } from 'react';

import moment from 'moment';

import { Container, Title, InputSelect, Left, Right } from './DueDate.styles';

const DueDate = ({ handlers }: { handlers: any }) => {
  const { dueDateList, handleSelectDueDate } = handlers;

  return (
    <Container>
      <Left>
        <Title>Data de vencimento:</Title>
      </Left>
      <Right>
        <InputSelect onChange={(e) => handleSelectDueDate(e.target.value)}>
          {dueDateList.length > 0 &&
            dueDateList.map((date: any) => (
              <option value={date.invoiceDueDate}>
                {moment(date.invoiceDueDate).format('DD/MM/YYYY')}
              </option>
            ))}
        </InputSelect>
      </Right>
    </Container>
  );
};

export default DueDate;
