import styled from 'styled-components';
export const Content = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: top;
  justify-content: center;
  margin-top: 50px;
`;

export const CardInfo = styled.div`
  flex: 1;
  background-color: var(--gray-008);
`;

export const CardLabel = styled.div`
  width: 100%;
  justify-content: flex-end;
  padding: 5px;
`;
export const ProgressContainer = styled.div`
  width: 100%;
  height: 35px;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-left: 15px;
  padding-right: 15px;
`;
export const TextLabel = styled.div<any>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  font-family: 'Helvetica Neue LT Std', Helvetica, Arial, serif;
  font-size: 14px;
  color: var(--gray-001);

  @media (max-width: 768px) {
    justify-content: flex-start;
  }
`;
export const TextLabelRes = styled.div<any>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  font-family: 'Helvetica Neue LT Std', Helvetica, Arial, serif;
  font-weight: bold;
  font-size: 14px;
  color: var(--gray-001);
  text-align: center;
  @media (max-width: 768px) {
    justify-content: flex-start;
  }
`;

export const Circular = styled.div`
  height: 10px;
  width: 10px;
  border-radius: 5px;
  margin-right: 5px;
  margin-top: 2px;
`;

export const CurrentProgress = styled.div`
  background-color: #04a4a1;
  height: 8px;
  border-radius: 5px;
`;

export const RemainderProgress = styled.div`
  background-color: #d3d5d9;
  height: 4px;
`;

export const CardStatus = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  @media (max-width: 768px) {
    justify-content: flex-start;
  }
`;
