import React, { useState, useEffect } from 'react';
import history from '../../services/history';
import {
  SideMain,
  MenuIcon,
  SideIcon,
  SearchIcon,
  Header,
  Body,
  Company,
  Footer,
  Label,
  Left,
  Right,
  LabelGrayMedium,
  LabelGrayLight,
  Center,
  Button,
  SideCardItem,
  BodyLeft,
  BodyRight,
  LabelGray,
} from './styles';
import logo from '../../assets/icons/MarcaColor.png';
import { connect, useDispatch } from 'react-redux';

import { sidebarRoutes } from 'routes/Menus';
import { signOut } from 'store/modules/authenticate/authenticate.actions';

const Sidebar = ({
  userName,
  companyName,
}: {
  userName?: string;
  companyName?: string;
}) => {
  const [options, setOptions] = useState(sidebarRoutes);

  const pathname = window.location.pathname;
  const dispatch = useDispatch();

  useEffect(() => {
    const optionIndex = options.findIndex((option) => {
      return option.link === pathname;
    });

    if (optionIndex >= 0) {
      const optionsInstance = options;

      optionsInstance.map((option) => {
        option.active = false;
      });

      optionsInstance[optionIndex].active = true;

      setOptions([...optionsInstance]);
    }
  }, []);

  const handleSignOut = () => {
    dispatch(signOut());
    history.push('/');
  };

  const chooseCompany = () => {
    history.push('/choose/company');
  };

  const handleOnClick = (item: { disabled: boolean; link: string }) => {
    if (item.disabled) {
      return;
    }
    const optionIndex = options.findIndex((option) => {
      return option.link === item.link;
    });

    if (optionIndex >= 0) {
      const optionsInstance = options;

      optionsInstance.map((option) => {
        option.active = false;
      });

      optionsInstance[optionIndex].active = true;

      setOptions([...optionsInstance]);
      history.push(item.link);
    }
  };

  let colorGreen = 'var(--primary)';
  let colorGray = 'var(--gray-003)';

  return (
    <SideMain>
      <Header>
        <MenuIcon src={logo} />
      </Header>
      <Body>
        {options.map((item) => {
          return (
            <SideCardItem onClick={() => handleOnClick(item)} key={item.link}>
              <BodyLeft>
                <SideIcon
                  style={{
                    background: item.active ? colorGreen : 'var(--white)',
                  }}
                >
                  {' '}
                  <SearchIcon
                    src={item.active ? item.iconActivated : item.icon}
                  />
                </SideIcon>
              </BodyLeft>

              <BodyRight>
                <Label
                  style={{
                    color: item.active ? colorGreen : colorGray,
                  }}
                >
                  {item.title}
                </Label>
              </BodyRight>
            </SideCardItem>
          );
        })}
      </Body>
      <Company>
        <LabelGrayLight>Você está na empresa:</LabelGrayLight>
        <LabelGrayMedium>{companyName}</LabelGrayMedium>
        <Center>
          <Button onClick={chooseCompany}>Mudar empresa</Button>
        </Center>
      </Company>
      <Footer>
        <Right>
          <LabelGrayLight>Operador:</LabelGrayLight>
          <LabelGrayLight>{userName}</LabelGrayLight>
          <LabelGray style={{ cursor: 'pointer' }} onClick={handleSignOut}>
            Sair
          </LabelGray>
        </Right>
      </Footer>
    </SideMain>
  );
};

const mapStateToProps = (state: any) => ({
  userName: state.authenticate?.user?.usuario,
  companyName: state.company.name,
});
export default connect(mapStateToProps)(Sidebar);
