import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  background-color: #fff;
`;

export const ImageBackground = styled.div`
  height: 100%;
  background: url('../../assets/background/bg1@2x.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Card = styled.div`
  height: 55%;
  width: 65%;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 10px 10px 68px -6px rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: space-between;

  @media (max-width: 992px) {
    width: 80%;
  }

  @media (max-width: 768px) {
    height: auto;
    padding: 20px 10px;
  }

  @media (max-height: 768px) {
    height: 70%;
  }
  @media (max-height: 576px) {
    height: 80%;
  }
`;

export const Image = styled.div`
  width: 100%;
  height: 100%;
  background: url('../../assets/img/intersection1@2x.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const Form = styled.div`
  width: 100%;
  height: 100%;
  padding: 4%;
`;

export const Logo = styled.img`
  width: 70%;
  height: auto;
`;

export const LogoBlack = styled.img`
  width: 60%;
  height: auto;
  margin-bottom: 20px;
  display: none;

  @media (max-width: 768px) {
    display: flex;
  }
`;

export const Title = styled.h5`
  margin-top: 30px;
  font-size: 1em;
  font-family: 'Nexa-Light';
  color: var(--white);
  text-align: center;
  align-items: center;
  display: none;

  @media (max-width: 768px) {
    display: flex;
  }
`;

export const Label = styled.label`
  font-family: 'Nexa-Black';
  color: var(--white);
  font-size: 1.1em;
  margin-left: 5px;
`;
