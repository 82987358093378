import React from 'react';
import {
  Container,
  Title,
  Row,
  Left,
  Right,
  LeftInput,
  MiddleInput,
  RightInput,
  LoadingWeb,
  LoadingMobile,
  Button,
} from './CreateUser.styles';
import InputLabel from 'components/InputLabel';
import Select from './components/Select/Select.component';
import Loading from 'components/Loading/Loading.component';
import { PhoneMask } from 'utils/Masks/PhoneMask';

function CreateUserScreen({ handlers }: { handlers: any }) {
  let {
    numeroDocumento,
    setNumeroDocumento,
    login,
    setLogin,
    nomeUsuario,
    setNomeUsuario,
    email,
    setEmail,
    numCelular,
    handlePhoneNumber,
    ddd,
    setDdd,
    ddi,
    setDdi,
    listaPerfis,
    setListaPerfis,
    companys,
    setCompanys,
    currentCompany,
    setCurrentCompany,
    loading,
    handleCreateUser,
    handleDocument,
  } = handlers;

  return (
    <Container>
      <Title>Criar Usuário</Title>
      <Row>
        <Left>
          <InputLabel
            value={nomeUsuario}
            onChange={setNomeUsuario}
            label="Nome"
            type="text"
            placeholder="Digite o nome do usuário"
          />
          <InputLabel
            value={email}
            onChange={setEmail}
            label="Email"
            type="text"
            placeholder="Digite o email do usuário"
          />
          <Row>
            <LeftInput>
              <InputLabel
                maxLength={3}
                number={true}
                value={ddi}
                onChange={setDdi}
                label="DDI"
                type="text"
                placeholder="( Ex. 55 )"
              />
            </LeftInput>
            <MiddleInput>
              <InputLabel
                maxLength={3}
                number={true}
                value={ddd}
                onChange={setDdd}
                label="DDD"
                type="text"
                placeholder="( Ex. 11 )"
              />
            </MiddleInput>
            <RightInput>
              <InputLabel
                maxLength={10}
                number={true}
                value={numCelular}
                onChange={handlePhoneNumber}
                label="Celular"
                type="text"
                placeholder="( Ex. 99999-9999 )"
              />
            </RightInput>
          </Row>
          {/* <Select onChange={setCurrentCompany} label="Empresa" options={companys} /> */}
          <LoadingWeb>
            {loading ? (
              <Loading size={20} />
            ) : (
              <Button onClick={() => handleCreateUser()}>Criar Usuário</Button>
            )}
          </LoadingWeb>
        </Left>
        <Right>
          <InputLabel
            value={login}
            onChange={setLogin}
            label="Login"
            type="text"
            placeholder="Digite o login do usuário"
          />
          {/* <InputLabel label="Perfil de Acesso" type="text" /> */}
          <InputLabel
            value={numeroDocumento}
            maxLength={14}
            onChange={handleDocument}
            number={true}
            label="Número do CPF"
            type="text"
            placeholder="Digite o número do documento do usuário"
          />
        </Right>
        <LoadingMobile>
          {loading ? (
            <Loading size={20} />
          ) : (
            <Button onClick={() => handleCreateUser()}>Criar Usuário</Button>
          )}
        </LoadingMobile>
      </Row>
    </Container>
  );
}

export default CreateUserScreen;
