import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 2;
  flex-direction: column;
  padding-right: 40px;

  @media (max-width: 576px) {
    padding: 0;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 576px) {
    justify-content: center;
  }
`;

export const Divider = styled.div`
  width: 1%;
`;

export const InputRadio = styled.input`
  margin-right: 5px;
`;

export const BorderBottom = styled.label`
  border-bottom: 2px solid black;
  width: 30%;

  @media (max-width: 576px) {
    display: none;
  }
`;

export const Title = styled.h5`
  font-family: Nexa-Heavy;
  color: var(--gray-001);
`;

export const LabelMedium = styled.label`
  font-family: 'Helvetica-Medium';
  color: var(--gray-001);
  font-size: 14px;
`;

export const AddressInformations = styled.div`
  margin-top: 30px;

  @media (max-width: 576px) {
    margin-top: 0;
  }
`;
