import React from 'react';
import { Content } from './ManageUser.styles';
import NavigationHeader from '../../components/NavigationHeader';
import Menu from 'components/Menu';
import ManageUserRouter from 'routes/ManageUser/index';
import { menuManageUser } from 'routes/Menus';

const ManageUserScreen = () => {
  return (
    <>
      <NavigationHeader />
      <Menu listMenu={menuManageUser} />
      <Content>
        <ManageUserRouter />
      </Content>
    </>
  );
};

export default ManageUserScreen;
