import styled from 'styled-components';

export const Drawer = styled.div`
  height: 70px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: none;

  @media (max-width: 992px) {
    display: flex;
  }
`;

export const Header = styled.div`
  background-repeat: no-repeat;
  background-size: cover;
  border: 1px solid #707070;
  background-position: center center;
  height: 180px;
  width: 100%;
  display: flex;
  @media (max-width: 768px) {
    height: 130px;
    width: 100%;
  }
`;
export const Descriptions = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
  @media (max-width: 768px) {
    justify-content: center;
    align-items: center;
  }
`;
export const LeftHeader = styled.div`
  flex: 2;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const MiddleHeader = styled.div`
  flex: 6;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 10px;
`;

export const Label = styled.h5`
  color: #fff;
  font-family: 'Nexa-Light';
  padding-bottom: 10px;
  font-size: 1.2em;
  @media (max-width: 768px) {
    font-size: 0.8em;
    text-align: center;
  }
`;
export const TitleHeader = styled.h5`
  color: var(--white);
  font-family: 'Nexa-Bold';
  font-size: 2.2em;
  @media (max-width: 768px) {
    font-size: 1.3em;
    text-align: center;
  }
`;

export const Left = styled.div`
  flex: 4;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Right = styled.div`
  flex: 2;
  text-align: center;
`;

export const LogoIcon = styled.img`
  width: 80%;
  height: auto;
`;

export const Menu = styled.img`
  width: 20px;
  height: auto;
`;
