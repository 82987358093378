import React from 'react';
import Popup from 'reactjs-popup';
import {Icon,Title,Label} from './PopUp.styles'
import iconTrash from 'assets/icons/trash.png'

const ConfirmPopUp = ({onClick,name}:{onClick:any;name:string;}) => (
  <Popup
    trigger={<Icon src={iconTrash} />}
    position="top right"
    nested
  >
    <Title>
      Tem certeza que deseja excluir o Usuário?
    </Title>
    <Label style={{marginTop:5,marginBottom:5}}>
      {name}.
    </Label>
    <Label>
    <button onClick={()=>onClick()}>Confirmar</button>
    </Label>
  </Popup>
);

export default ConfirmPopUp