import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const TitleHeader = styled.label`
  color: var(--white);
  font-family: 'Nexa-Bold';
  font-size: 3.4rem;
`;

export const LogoIcon = styled.img`
  width: 254px;

  @media (max-width: 768px) {
    width: 50%;
  }
`;

export const Header = styled.div`
  background-repeat: no-repeat;
  background-size: cover;
  border: 1px solid #707070;
  background-position: center center;
  height: 180px;
  width: 100%;
  display: flex;
`;

export const Row = styled.div`
  display: flex;
  background-color: #eeeff6;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;
export const Left = styled.div`
  padding: 10px;
  height: 100%;
  flex: 1;

  @media (max-width: 992px) {
    display: none;
  }

  @media (max-width: 600px) {
    height: 0vw;
    width: 0vw;
  }
`;
export const Middle = styled.div`
  padding: 10px;
  height: 100%;
  flex: 4;
`;

export const Label = styled.h5`
  color: #fff;
  font-family: 'Nexa-Light';
  padding-bottom: 10px;
  font-size: 25px; ;
`;

export const Descriptions = styled.div`
  display: flex;

  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
`;
export const LeftHeader = styled.div`
  flex: 2;
`;

export const MiddleHeader = styled.div`
  flex: 6;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 10px;
`;

export const Footer = styled.div`
  display: flex;
  width: 100%;
  height: 100px;
  background: var(--gray-006);
  align-items: center;
  justify-content: flex-end;
  padding-right: 40px;
  @media (max-width: 768px) {
    height: 60px;
  }
`;
