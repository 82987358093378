export const setAccounts = (data: any) => {
  return { type: '@relationship/SET_ACCOUNTS', data };
};

export const setCurrentAccount = (currentAccount: any) => {
  return { type: '@relationship/SET_CURRENT_ACCOUNT', currentAccount };
};

export const setCurrentCardStatus = (status: string) => {
  return { type: '@relationship/SET_CURRENT_CARD_STATUS', status };
};

export const setDataAccount = (data: any) => {
  return { type: '@relationship/SET_CURRENT_DATA_ACCOUNT', data };
};

export const setCurrentCard = (card: any) => {
  return { type: '@relationship/SET_CURRENT_CARD', card };
};

export const setCurrentLimitiCard = (currentLimit: any) => {
  return { type: '@relationship/SET_CURRENT_LIMIT_CARD', currentLimit };
};
export const updateAccountStatus = (accountId: string, status: string) => {
  return { type: '@relationship/UPDATE_ACCOUNT_STATUS', accountId, status };
};

export const setCurrentPage = (currentPage: number) => {
  return { type: '@relationship/SET_CURRENT_PAGE', currentPage };
};
