import styled from 'styled-components';
export const DivMain = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background: var(--gray-008);
`;
export const DivLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 30%;
  height: 80%;
  //height: 100vh;
  background: var(--gray-008);
`;
export const DivRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: top;
  width: 70%;
  height: 80%;
  background: var(--gray-008);
  margin-right: 58px;
`;

export const Container = styled.div`
  display: flex;

  flex: 1;
  flex-direction: column;
  background-color: var(--gray-008);
`;

export const Header = styled.div`
  width: 100vw;
  height: 30vh;
  display: flex;
  justify-content: center;
  background: transparent url('../../assets/img/bg5@2x.png') 0% 0% no-repeat
    padding-box;
  background-size: cover;
  border: 1px solid #707070;
  opacity: 1;
  background-position: center center;

  @media (max-width: 768px) {
    transition: height 0.3s ease;
    height: 15vh;
  }
  @media (min-width: 768px) {
    transition: height 0.3s ease;
    height: 30vh;
  }
`;

export const Body = styled.div`
  top: 0px;
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
`;
export const TitleHeader = styled.label`
  margin-right: 25%;
  margin-top: 9%;
  color: var(--white);
  font-family: Nexa-Bold;
  font-size: 5.6rem;
  font-style: normal;
  font-weight: 900;
  letter-spacing: -1.4px;
  line-height: 5.6rem;
  opacity: 1;

  @media (max-width: 480px) {
    font-size: 20;
  }
`;
export const DivConsult = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 43px;
`;

export const DivOrder = styled.div`
  width: 20%;
  display: flex;
  direction: row;
  position: relative;
`;

export const DivRowList = styled.div`
  display: flex;
  direction: row;
  align-items: center;
  align-content: left;
  justify-content: space-around;
  padding: 0px;
  background-color: var(--white);
  margin-top: 5px;
  border-radius: 8px;
  text-align: left;
  opacity: 1;
  margin-bottom: 16px;
  height: 55px;
`;

export const DivPipe = styled.div`
  background-color: var(--gray-001);
  height: 10px;
  width: 1px;
`;
export const DivLine = styled.div`
  background-color: var(--gray-006);
  height: 2px;
  width: 100%;
  margin-bottom: 30px;
`;

export const DivPipeWhite = styled.div`
  background-color: var(--gray-006);
  height: 10px;
  width: 1px;
`;

export const DivCircleRed = styled.div`
  background-color: red;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin-left: 10px;
  margin-top: 2px;
`;

export const DivCircleGreen = styled.div`
  background-color: green;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin-left: 10px;
  margin-top: 2px;
`;

export const DivList = styled.div`
  display: flex;
  direction: row;
  align-items: center;
  justify-content: space-around;
  padding: 5px;
  //padding-right: 200px;
  margin-top: 35px;
  margin-left: 50px;
  border-radius: 10px;
  text-align: center;
`;
/*export const SearchIconCh = styled.img`
  width: 20px;
  height: 15px;
  margin-left: 10px;
  background-color: transparent;
`;*/
export const DownIcon = styled.img`
  width: 1.2rem;
  height: 1.2rem;
  margin-left: 50px;
`;

export const Button = styled.button`
  height: 40px;
  width: 15%;
  background-color: #fff;
  border-radius: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--gray-001);
  border-width: 1px;
  border-color: var(--gray-001);
  font-family: 'Helvetica-Medium';
  padding-left: 3px;
  padding-top: 4px;
  cursor: pointer;
  &:hover {
  }
  &:active {
    // background-color: #00ccc9;
  }
`;

export const Icon = styled.img`
  height: 24px;
  padding-left: 40px;
  padding-bottom: 4px;
  padding-right: 4px;
`;

export const Loading = styled.div`
  height: 70px;
  width: 70px;
  background-color: blue;
  display: flex;
  justify-content: center;
  align-self: center;
  align-items: center;
`;
export const IconLoading = styled.img`
  height: 50px;
`;
