import styled from 'styled-components';
export const Main = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  //height: 100vh;
  background: var(--gray-008);
  position: relative;
`;
export const MenuStyles = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 460px;
  height: 100%;
  //height: 100vh;
  background: var(--gray-008);
`;
export const Container = styled.div`
  display: flex;
  background: var(--gray-007);
`;

export const Header = styled.div`
  width: 100vw;
  height: 30vh;
  display: flex;
  justify-content: center;
  background: transparent url('../../assets/img/bg5.png') 0% 0% no-repeat
    padding-box;
  background-size: cover;
  border: 1px solid #707070;
  opacity: 1;
  background-position: center center;

  @media (max-width: 768px) {
    transition: height 0.3s ease;
    height: 15vh;
  }
  @media (min-width: 768px) {
    transition: height 0.3s ease;
    height: 30vh;
  }
`;

export const Body = styled.div`
  top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const TitleHeader = styled.label`
  margin-right: 25%;
  margin-top: 9%;
  color: var(--white);
  font-family: 'Nexa-Bold';
  font-size: 5.6rem;

  font-style: normal;
  font-weight: 900;
  letter-spacing: -1.4px;
  line-height: 5.6rem;
  opacity: 1;

  @media (max-width: 480px) {
    font-size: 3rem;
  }
  @media (max-width: 1500px) {
    font-size: 5rem;
    margin-right: 15%;
    margin-top: 10%;
  }
`;
export const DivConsult = styled.div`
  display: flex;
  direction: row;
  //align-items: center;
  //justify-content: space-between;

  margin-top: 43px;

  //background-color: green
`;

export const DivRowList = styled.div`
  display: flex;
  direction: row;
  align-items: center;
  align-content: left;
  justify-content: space-around;
  padding: 0px;
  background-color: var(--white);
  margin-top: 16px;
  border-radius: 8px;
  text-align: left;
  opacity: 1;
`;

export const DivPipe = styled.div`
  background-color: var(--gray-001);
  height: 10px;
  width: 1px;
`;
export const DivLine = styled.div`
  background-color: var(--gray-006);
  height: 2px;
  width: 100%;
  margin-top: 65px;
  opacity: 1;
`;

export const DivPipeWhite = styled.div`
  background-color: var(--gray-006);
  height: 10px;
  width: 1px;
`;

export const DivCircleRed = styled.div`
  background-color: red;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin-left: 10px;
  margin-top: 2px;
`;

export const DivCircleGreen = styled.div`
  background-color: green;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin-left: 10px;
  margin-top: 2px;
`;

export const DivList = styled.div`
  display: flex;
  direction: row;
  align-items: center;
  justify-content: space-around;
  padding: 5px;
  //padding-right: 200px;
  margin-top: 35px;
  margin-left: 50px;
  border-radius: 10px;
  text-align: center;
`;

export const Pagination = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 70px;
  border-radius: 25px;
  background: var(--gray-008) 0% 0% no-repeat padding-box;
  opacity: 1;
  position: unset;
  align-items: center;
  justify-content: center;
`;
export const Next = styled.div`
  width: 30px;
  height: 30px;
  align-items: center;
  display: inline-block;
  background-color: var (--white);
  color: black;
  background-color: var(--white);
  color: var (--black);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  cursor: pointer;
`;

export const Previous = styled.div`
  width: 30px;
  height: 30px;
  align-items: center;
  display: inline-block;
  background-color: var (--white);
  color: black;
  background-color: var(--white);
  color: var (--black);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  cursor: pointer;
`;

export const PaginationNunbers = styled.div`
  display: flex;
  justify-content: space-between;
  background: var(--white);
  height: 35px;
  width: 15%;
  border-radius: 25px;
  align-items: center;
  padding: 5px;
  margin: 30px;
  padding-left: 30px;
  padding-right: 30px;
  cursor: pointer;
`;

export const Footer = styled.div`
  display: flex;
  width: 100%;
  height: 100px;
  top: 100%;
  align-items: bottom;
  background: var(--gray-006) 0% 0% no-repeat padding-box;
  opacity: 1;
  position: unset;
  align-items: center;
  justify-content: flex-end;
  padding-right: 70px;
`;

export const LogoIcon = styled.div`
  width: 254px;
  height: 30px;
  display: flex;
  flex-direction: row;
  //position: relative;

  //justify-content: center;
  background: transparent url('../../assets/img/G9677.png') 0% 0% no-repeat;

  background-size: cover;

  opacity: 1;
`;
export const Row = styled.div`
  display: flex;
  background-color: #eeeff6;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Left = styled.div`
  flex: 1;
`;

export const Content = styled.div`
  padding: 10px;
  flex: 6;
`;

export const InformationArea = styled.div`
  display: flex;
  @media (max-width: 768px) {
    display: none;
  }
`;
export const Informations = styled.div`
  padding: 20px;
  flex: 2;

  @media (max-width: 1024px) {
    height: 50vw;
  }
`;
export const Card = styled.div`
  display: flex;
  flex: 2;
`;
export const Separator = styled.div`
  width: 2px;
  height: 18vw;
  background-color: #e0e0e4;
  @media (max-width: 600px) {
    width: 0;
  }
`;
export const Divider = styled.div`
  display: flex;
  flex-direction: row;
`;
