import React from 'react';
import { Route, Switch } from 'react-router-dom';
import SignIn from 'containers/SignIn/signIn.controller';
import ForgotPassword from 'containers/ForgotPassword/ForgotPassword.controller';
import ChooseCompany from 'containers/ChooseCompany/ChooseCompany.controller';
import PrivateRoute from '../components/PrivateRoutes';

function PageNotFound() {
  return <h1>Pagina não encontrada</h1>;
}

function PublicRoute() {
  return (
    <Switch>
      <Route exact path="/forgot_password" component={ForgotPassword} />
      <PrivateRoute exact path="/choose/company" component={ChooseCompany} />
      <Route component={SignIn} />
      {/* <Route path="*" component={PageNotFound} /> */}
    </Switch>
  );
}

export default PublicRoute;
