import styled from 'styled-components';
import creditCardGreen from '../../../../assets/images/cardGreen.png';
import creditCardBlack from '../../../../assets/images/cardBlack.png';
import newBlueCard from 'assets/svg/newBlueCard.svg';
import newBlackCard from 'assets/svg/newBlackCard.svg';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
`;

export const Left = styled.div`
  flex: 1;
`;

export const CreditCardArea = styled.div`
  display: flex;
`;

export const Middle = styled.div`
  flex: 1;
`;

export const Right = styled.div`
  flex: 1;

  @media (max-width: 576px) {
    margin-left: 10px;
  }
`;

export const CreditCardGreen = styled.div`
  width: 193px;
  cursor: pointer;
  background-image: url(${newBlueCard});
  height: 327px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  @media (max-width: 576px) {
    width: 159px;
    height: 270px;
  }
`;

export const CreditCardBlack = styled(CreditCardGreen)`
  background-image: url(${newBlackCard});
`;
