import React from 'react';
import {
  Container,
  Title,
  Label,
  Button,
  Row,
  InputLabel,
  InputRadio,
} from './Request2nd.styles';
import Loading from 'components/Loading/Loading.component';

function Request2ndScreen({ handlers }: { handlers: any }) {
  const {
    setReasonReissueCard,
    loading,
    handleRequest2nd,
    currentCard,
  } = handlers;

  return (
    <Container>
      <Title>Solicitar 2ª via</Title>
      <Label>
        Qual o motivo da solicitação da 2ª via do cartão final{' '}
        {currentCard?.last4Digits}?
      </Label>
      <Row>
        <InputRadio
          type="radio"
          onChange={(e) => setReasonReissueCard(e.target.value)}
          id="reason"
          name="reason"
          value="P"
        />
        <InputLabel>Perda</InputLabel>
      </Row>
      {/* <Row>
                <InputRadio type="radio" onChange={(e)=>setReasonReissueCard(e.target.value)} id="reason" name="reason" value="R" />
                <InputLabel>Roubo</InputLabel>
            </Row> */}
      <Row style={{ marginBottom: '40px' }}>
        <InputRadio
          type="radio"
          onChange={(e) => setReasonReissueCard(e.target.value)}
          id="reason"
          name="reason"
          value="O"
        />
        <InputLabel>Outros</InputLabel>
      </Row>

      {loading ? (
        <Loading size={25} />
      ) : (
        <Button onClick={handleRequest2nd}>
          Confirmar solicitação de 2ª Via
        </Button>
      )}
    </Container>
  );
}

export default Request2ndScreen;
