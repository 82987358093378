import React, { useEffect, useState } from 'react';
import {
  Content,
  Card,
  InformationArea,
  Informations,
  Row,
  Separator,
  Divider,
} from './styles';
import NavigationHeader from '../../components/NavigationHeader';
import Menu from '../../components/Menu';
import CardsRoute from '../../routes/Cards';
import CreditCard from '../../components/CreditCard/CreditCard.component';
import CardInfo from '../../components/CardInfo/CardInfo.components';

import greenCard from 'assets/images/cardGreen.png';
import blackCard from 'assets/images/cardBlack.png';
import newBlackCard from 'assets/svg/newBlackCard.svg';
import newBlueCard from 'assets/svg/newBlueCard.svg';

import horizontalGreenCard from 'assets/images/horizontalCardGreen.png';
import horizontalBlackCard from 'assets/images/horizontalCardBlack.png';
import newBlackCardHorizontal from 'assets/svg/newBlackCardHorizontal.svg';
import newBlueCardHorizontal from 'assets/svg/newBlueCardHorizontal.svg';

import Loading from 'components/Loading/Loading.component';
import { detailsMenu } from 'routes/Menus';
import { connect } from 'react-redux';

const CardsLayout = ({
  currentAccount,
  currentCard,
}: {
  currentAccount: any;
  currentCard: any;
}) => {
  const [loading, setLoading] = useState(true);
  const [cardInfo, setCardInfo] = useState({
    account: '',
    cardHolder: '',
    cardStatus: '',
    currentBalance: '',
    documentNumber: '',
    last4Digits: '',
    relationshipBillingLevel: '',
  });
  const [card, setCard] = useState('');

  useEffect(() => {
    getAccountData();
  }, []);

  const getAccountData = () => {
    setCardInfo(currentAccount);
    verifyCardType();

    setLoading(false);
  };

  function verifyCardType() {
    if (currentCard.cardsResult[0].numLogo === 1) {
      if (window.innerWidth > 768) {
        return setCard(newBlueCard);
      }
      return setCard(horizontalGreenCard);
    }
    if (window.innerWidth > 768) {
      return setCard(newBlackCard);
    }

    return setCard(newBlackCardHorizontal);
  }

  return (
    <>
      <NavigationHeader />
      <Menu listMenu={detailsMenu} />

      <Row>
        <Content>
          {loading ? <Loading icon={true} size={40} /> : <CardsRoute />}
        </Content>
        <InformationArea>
          <Informations>
            <Divider>
              <Separator />
              <CardInfo />
            </Divider>
          </Informations>
          <Card>
            <CreditCard card={card} />
          </Card>
        </InformationArea>
      </Row>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  currentAccount: state.relationship.currentAccount,
  currentCard: state.relationship.currentCard,
});
export default connect(mapStateToProps)(CardsLayout);
