import React, { useState, useEffect } from 'react';
import ManageLimitScreen from './ManageLimit.screen';
import { putLimitManagement } from 'store/modules/companyCards/companyCards.endpoints';
import { getLimitManagement } from 'store/modules/companyCards/companyCards.endpoints';
import { connect, useDispatch } from 'react-redux';
import { setCurrentLimitiCard } from 'store/modules/relationship/relationship.actions';
import Loading from 'components/Loading/Loading.component';

interface ICurrentCard {
  last4Digits: string;
  uuid: string;
}
interface IConsultCard {
  activeFlag: string;
  blockCode: string;
}

function ManageLimitController({
  currentCard,
  currentAccount,
  consultCard,
}: {
  currentCard: ICurrentCard;
  currentAccount: { creditLimitNac: number; creditLimitNacAvailable: number };
  consultCard: IConsultCard;
}) {
  const [limit, setLimit] = useState('');
  const [currentLimit, setCurrentLimit] = useState({});
  const [permanentBlock, setPermanentBlock] = useState(false);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const used =
    currentAccount.creditLimitNac - currentAccount.creditLimitNacAvailable;

  const creditUsed = parseFloat(used.toString()).toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  useEffect(() => {
    if (
      (consultCard.activeFlag === 'S' && consultCard.blockCode) ||
      consultCard.activeFlag === 'N'
    ) {
      setPermanentBlock(true);
      setLoading(false);
    } else {
      getLimitCreditCard();
    }
  }, []);

  const getLimitCreditCard = async () => {
    try {
      setLoading(true);
      const { uuid, last4Digits } = currentCard;

      const { data } = await getLimitManagement(uuid, last4Digits);

      dispatch(setCurrentLimitiCard(data));
      setCurrentLimit(data);

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleLimit = async () => {
    try {
      const { last4Digits, uuid } = currentCard;
      const body = {
        availableLimit: parseFloat(limit),
        changeLimit: 'S',
        last4digits: last4Digits,
      };
      await putLimitManagement(uuid, body);
      await getLimitCreditCard();
      alert('Limite alterado com sucesso!');
    } catch (error) {
      alert('Ocorreu um erro ao alterar o limite!');
    }
  };

  const handlers = {
    limit,
    setLimit,
    handleLimit,
    loading,
    setLoading,
    currentLimit,
    creditUsed,
    permanentBlock,
  };
  return loading ? (
    <Loading size={40} icon={true} />
  ) : (
    <ManageLimitScreen handlers={handlers} />
  );
}

const mapStateToProps = (state: any) => ({
  currentCard: state.relationship.currentCard,
  currentAccount: state.relationship.currentAccount,
  limitsCreditCard: state.relationship.limitsCreditCard,
  consultCard: state.companyCards.consultCard,
});
export default connect(mapStateToProps)(ManageLimitController);
