import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
  align-content: center;
  margin-bottom: 15px;

  .notMobile {
    @media (max-width: 768px) {
      display: none;
    }
  }
`;

export const Title = styled.h5`
  font-family: 'Nexa-Bold';
  font-size: 16px;
  text-align: center;
  color: var(--gray-001);
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export const Header = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  .notMobile {
    @media (max-width: 768px) {
      display: none;
    }
  }
`;

export const SeparatorTitle = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 20px 10px;
`;
export const Separator = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 20px 10px;

  & + div {
    border-left: 1px solid #2c2c2c;
  }
`;

export const Label = styled.span`
  font-family: 'Helvetica-Medium';
  font-size: 12px;
  text-align: center;
  color: var(--gray-001);

  @media (max-width: 1400px) {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: -webkit-box;
    width: 100px;
    line-clamp: 3;
    box-orient: vertical;
    visibility: visible;
  }
  @media (max-width: 992px) {
    width: 80px;
  }
`;

export const Button = styled.button`
  width: 55%;
  height: 35px;
  border-radius: 20px;
  background-color: var(--white);
  border: 1px solid #566068;
  font-family: 'Helvetica-Medium';
  cursor: pointer;
`;

export const Icon = styled.img`
  height: 18px;
  cursor: pointer;
  @media (max-width: 768px) {
    height: 14px;
    margin-right: 10px;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 4px;
`;

export const ButtonTitle = styled.h5`
  font-family: 'Helvetica-Medium';
  font-size: 14px;
  color: var(--gray-001);
  text-align: right;
`;

export const Left = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

export const Spacement = styled.div`
  flex: 1;
`;
export const Right = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

export const Middle = styled.div`
  flex: 3;
  align-self: center;
`;

export const Circular = styled.div`
  height: 10px;
  width: 10px;
  border-radius: 5px;
  margin-left: 5px;
  margin-bottom: 4px;
`;
