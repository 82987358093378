import React from 'react';
import { Switch } from 'react-router-dom';
import PrivateRoute from '../components/PrivateRoutes';
import ListCards from '../../containers/Home/Home.controller';
import Invoices from '../../containers/Invoices/Invoices.controller';
import RequestCard from '../../containers/RequestCard/RequestCard.controller';
import CardsLayout from '../../layouts/Cards';
import ManageUser from 'layouts/ManageUser/ManageUser.controller';
import ManageCompany from 'layouts/ManageCompany/ManageCompany.controller';

function UserRoute() {
  return (
    <Switch>
      <PrivateRoute exact path="/home/invoices" component={Invoices} />
      <PrivateRoute exact path="/home/requestcard" component={RequestCard} />
      <PrivateRoute path="/home/details" component={CardsLayout} />
      <PrivateRoute path="/home/user/management" component={ManageUser} />
      <PrivateRoute path="/home/company/management" component={ListCards} />
      <PrivateRoute exact path="/home" component={ListCards} />
    </Switch>
  );
}

export default UserRoute;
