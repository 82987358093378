import React from 'react';
import { Switch, Route } from 'react-router-dom';
import PublicLayout from '../layouts/Public';
import PrivateRoute from './components/PrivateRoutes';
import UserLayout from '../layouts/User';

function Routes() {
  return (
    <Switch>
      <PrivateRoute path="/home" component={UserLayout} />
      <Route component={PublicLayout} />
    </Switch>
  );
}

export default Routes;
