import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 70px;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
`;

export const InputSelect = styled.select`
  border-color: transparent;
  border-width: 0px;
  border: none;
  height: 30px;
  width: 50%;
  outline: none;
  color: var(--gray-001);
  border-radius: 15px;
  font-family: 'Helvetica-Normal';
  padding-left: 10px;
`;
export const Left = styled.div`
  flex: 1;
`;
export const Right = styled.div`
  flex: 3;
`;
export const Title = styled.h3`
  font-family: Nexa-XBold;
  color: var(--gray-001);
  margin-bottom: 30px;
  margin-top: 30px;
`;
