import styled, { createGlobalStyle, css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const DivLine = styled.div`
  display: flex;
  background-color: var(--gray-006);
  height: 2px;
  width: 100%;
  opacity: 1;
`;

export const Unavailable = styled.div`
  height: 50vh;
  font-family: Nexa-XBold;
  color: var(--gray-001);
  display: flex;
  justify-content: center;
  align-items: center;
`;
