import styled from 'styled-components';

export const Container = styled.form`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
`;
export const Top = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;

  @media (max-width: 768px) {
    align-items: center;
    padding: 0 20px;
  }
`;

export const Middle = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  flex: 2;
  justify-content: center;

  @media (max-width: 576px) {
    padding-top: 10px;
  }
`;

export const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

export const Title = styled.h1`
  font-size: 28px;
  color: var(--primary);
  margin-bottom: 10px;
  font-family: Nexa-XBold;

  @media (max-height: 768px) {
    font-size: 22px;
  }
`;
export const Description = styled.span`
  color: var(--gray-001);
  font-family: 'Helvetica-Normal';
  font-size: 18px;

  @media (max-width: 576px) {
    font-size: 16px;
  }
  @media (max-height: 768px) {
    font-size: 16px;
    text-align: start;
  }
`;

export const Text = styled.h5`
  font-family: 'Helvetica Neue LT Std';
`;

export const Label = styled.label`
  font-family: 'Helvetica Neue LT Std', Helvetica, Arial, serif;
  font-size: 1.4rem;
  text-align: end;
  color: var(--primary);
  cursor: pointer;
`;
