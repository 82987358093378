import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const Blocked = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  font-family: 'Nexa-Heavy';
  color: var(--gray-001);
  padding: 20px 0;
`;

export const Title = styled.h2`
  font-family: 'Nexa-Heavy';
  color: var(--gray-001);
  margin-bottom: 30px;
  margin-top: 30px;

  @media (max-width: 768px) {
    margin: 0;
  }
`;

export const Label = styled.label`
  font-family: 'Helvetica-Normal';
  color: var(--gray-001);
  font-size: 14px;
`;

export const AmountLabel = styled.h3`
  font-family: 'Nexa-Bold';
  color: var(--gray-001);
`;

export const Button = styled.button`
  width: 100%;
  height: 40px;
  border-radius: 30px;
  background-color: var(--primary);
  border: none;
  color: #fff;

  &:hover {
    background-color: #00a4a1;
  }
  &:active {
    background-color: #00ccc9;
  }
`;
export const Box = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  padding: 25px;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    padding: 20px 0;
  }
`;
export const CurrentLimit = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 80%;
  background-color: green;
  border-radius: 25px;
  background-color: #fff;
  padding: 10px;
  @media (max-width: 900px) {
    height: 120px;
  }
  @media (max-width: 768px) {
    height: 100px;
    width: 100%;
    margin-bottom: 10px;
  }
`;
export const Left = styled.div`
  display: flex;
  flex: 1;
`;
export const Right = styled.div`
  display: flex;
  flex: 3;
  flex-direction: column;
`;

export const Icon = styled.img`
  height: 30px;
  width: 30px;
  margin-left: 15px;
`;

export const NewLimit = styled.div`
  padding-left: 5px;
`;

export const Form = styled.label`
  width: 100%;
  padding-left: 10px;
`;
