import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    padding: 20px 0;
    border-bottom: 1px solid var(--gray-005);
    margin-bottom: 20px;

    @media (max-width: 768px) {
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
    }
`

export const Content = styled.div`
    display: flex;
    flex: 5;
    flex-direction: row;
    width: 100%;
    background: var(--gray-008);
    padding: 20px;
    overflow: auto;
    white-space: nowrap;
`

export const MenuBox = styled.button<any>`
    height: 100px;
    border-color: transparent;
    border-radius: 20px;
    cursor: pointer;
    width: 15%;
    margin-right: 10px;
    margin-bottom: 5px;
    margin-left: 6px;
    margin-top: 5px;
    text-align: left;
    
    @media (max-width: 1024px) {
        width: 22%;
    }
    @media (max-width: 768px) {
        width: 65%;
        height: 90px;
        padding:5px;
    }
`

export const MenuIcon = styled.img`
    margin-left: 5px;
    height: 25px;
    margin-right: 5px;
    @media (max-width: 600px) {
        width: 15px;
        margin-right: 1px;
    }
    @media (max-width: 768px) {
        height: 18px;
        width: auto;
    }
`

export const DivLine = styled.div`
    display: flex;
    background-color: var(--gray-006);
    height: 2px;
    width: 100%;
    opacity: 1;
    margin-bottom: 20px;
`

export const Left = styled.div`
    display: flex;
    flex: 2;
    align-items: center;
    justify-content: center;
`

export const Right = styled.div`
    display: flex;
    flex: 3;
`

export const Row = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
`
export const Title = styled.h3`
    color: var(--white);
    font-size: 1em;
    @media (max-width: 768px) {
        font-size: 0.8em;
        text-align:left;
    }
`
