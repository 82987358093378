import React from 'react'
import HomeIcon from '../../assets/icons/home.png'
import PreviusIcon from '../../assets/icons/path-104@1x.png'
import { HeaderMenu } from '../../routes/Menus'

import {
    TitleBack,
    Content,
    CurrentPage,
    DivLine,
    IconHome,
    IconPrevious,
    MainNavigation,
    Pipeline,
    IconChevrons,
    Left,
    Right,
    BackPage,
    Title,
} from './styles'
import { useLocation, useHistory } from 'react-router-dom'
import chevrons from 'assets/icons/chevrons.png'

const NavigationHeader: React.FC = () => {
    const history = useHistory()

    const handleBack = () => {
        history.push('/home')
    }
    const location = useLocation()

    const currentRoute = HeaderMenu.find(
        (route) => route.link === location.pathname
    )
    return (
        <Content>
            <MainNavigation>
                <Left>
                    <BackPage onClick={handleBack}>
                        <IconPrevious onClick={handleBack} src={PreviusIcon} />
                        <TitleBack>Voltar</TitleBack>
                    </BackPage>
                </Left>
                <Right>
                    <IconHome src={HomeIcon} />
                    <IconChevrons src={chevrons} />
                    <Title>{currentRoute?.menu}</Title>
                    <IconChevrons src={chevrons} />
                    <Title>{currentRoute?.title}</Title>
                </Right>
            </MainNavigation>
            <DivLine />
        </Content>
    )
}

export default NavigationHeader
