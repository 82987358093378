import produce from 'immer';

export const INITIAL_STATE = {
  last4digits: '',
  action: '',
  consultCard: {},
};

export default function CompanyCardsReducer(
  state = INITIAL_STATE,
  action: any
) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@companyCards/ACTIVATECARD': {
        draft.last4digits = action.last4digits;
        draft.action = action.action;
        break;
      }
      case '@companyCards/DISABLECARD': {
        draft.last4digits = action.last4digits;
        break;
      }
      case '@companyCards/SET_CONSULT_CARD': {
        draft.consultCard = action.consult;
        break;
      }
      default:
    }
  });
}
