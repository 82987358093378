import styled, { createGlobalStyle, css } from "styled-components";

export const InputSourceTag = styled.input`
  height: 45px;
  width: 100%;
  background-color: var(--white);
  border-radius: 22px;
  border:0;
  padding-left: 30px;
  outline: none;
  margin-right:3px;
  color: var(--soft-gray);
`;

export const Button = styled.button`
  display:flex;
  height: 45px;
  flex-direction:row;
  justify-content:center;
  align-items:center;
  width: 30%;
  background-color: var(--primary);
  border-radius: 22px;
  border: 1px solid var(--gray-006);
  color: var(--white);
  font-size: 1.4rem;
  cursor: pointer;

  :hover {
    background: var(--primary-02);
  }
  @media (max-width: 1024px) {
    font-size: 1rem;
}
`;

export const DivInputSource = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  position: relative;
  margin-right: 30px;
  background: var(--white);
  border-radius: 20px;
`;

export const SearchIcon = styled.img`
  width: 1.4rem;
  height: 1.4rem;
  @media (max-width: 1024px) {
    width: 1.2rem;
  height: 1.2rem;
}
`;

export const TextButton = styled.div`
  margin-right:4px;

  @media(max-width: 768px){
    display: none;
  }
`;
