import React, { useState, useEffect } from 'react';
import SignInScreen from './signIn.screen';
import {
  getTokenOrbital,
  postSignIn,
} from 'store/modules/authenticate/authenticate.endpoints';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  setDataAccounts,
  setToken,
} from 'store/modules/authenticate/authenticate.actions';
import { connect } from 'react-redux';

function SignInController({ token }: { token: string }) {
  // const [username, setUsername] = useState('vinicius.oliveira');
  // const [password, setPassword] = useState('Stefanini@2020');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  let history = useHistory();
  const dispatch = useDispatch();

  // useEffect(() => {
  //   if (token) {
  //     history.push('/choose/company');
  //   }
  // }, []);

  const handleSignIn = async () => {
    try {
      if (!username || !password) {
        return alert('Preencha todos os campos');
      }
      setLoading(true);
      const { tokenBackEnd } = await getTokenOrbital();

      const token = `Bearer ${tokenBackEnd.OAuth2AccessToken.access_token}`;
      dispatch(setToken(token));

      const response = await postSignIn({
        login: username.toLocaleLowerCase(),
        senha: password,
      });

      dispatch(setDataAccounts(response));

      setLoading(false);
      history.push('/choose/company');
    } catch (error) {
      setLoading(false);
      alert(
        'Ocorreu um erro ao realizar o login, verifique os dados digitados!'
      );
    }
  };
  const handlers = {
    handleSignIn,
    username,
    password,
    setUsername,
    setPassword,
    loading,
  };

  return <SignInScreen handlers={handlers} />;
}

const mapStateToProps = (state: any) => ({
  token: state.authenticate.token,
});
export default connect(mapStateToProps)(SignInController);
