import React from 'react';
import {
  CardInfo,
  CardLabel,
  TextLabel,
  TextLabelRes,
  ProgressContainer,
  Circular,
  CurrentProgress,
  RemainderProgress,
  CardStatus,
} from './CardInfo.styles';
import { connect } from 'react-redux';
import { maskCPFeCNPJ } from 'utils/Masks/DocumentMask';
import { MoneyMask } from 'utils/Masks/MoneyMask';

function CardInfoComponent({
  currentCard,
  currentLimitCard,
  currentAccount,
}: {
  currentCard: any;
  currentLimitCard: any;
  currentAccount: any;
}) {
  console.log(currentCard)
  const cardResult = currentCard?.cardsResult
    .filter((card: any) => card.uuid === currentCard.uuid)[0];
  const translaterStatus: any = {
    Blocked: { label: 'Inativo', color: 'red' },
    Actived: { label: 'Ativo', color: '#2ECD7A' },
  };

  const translaterRelation: any = {
    0: 'Centralizado',
    1: 'Descentralizado',
    2: 'Misto',
  };

  const relation = translaterRelation[currentAccount?.flagTypeBilling];
  const status = translaterStatus[cardResult.status];

  const limitCard = currentLimitCard?.creditLimit;
  const currentPercentage =
    (currentLimitCard?.availableCreditLimitUsed * 100) / limitCard;
  const remainderPercentage = 100 - currentPercentage;

  const used =
    currentAccount.creditLimitNac - currentAccount.creditLimitNacAvailable;

  const creditUsed = parseFloat(used.toString()).toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  const limitFormated = parseFloat(
    currentAccount?.creditLimitNac
  ).toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  return (
    <CardInfo>
      <CardLabel>
        <TextLabel>Nome do portador:</TextLabel>
        <TextLabelRes>{currentAccount?.clientNameHolder}</TextLabelRes>
      </CardLabel>

      <CardLabel>
        <TextLabel>CPF do portador:</TextLabel>
        <TextLabelRes>
          {maskCPFeCNPJ(currentAccount?.documentNumber)}
        </TextLabelRes>
      </CardLabel>
      <CardLabel>
        <TextLabel>Final do cartão:</TextLabel>
        <TextLabelRes>**** **** **** {currentCard?.last4Digits}</TextLabelRes>
      </CardLabel>
      <CardLabel>
        <TextLabel>Modalidade:</TextLabel>
        <TextLabelRes>{relation}</TextLabelRes>
      </CardLabel>
      <CardLabel>
        <TextLabel>Status:</TextLabel>
        <CardStatus>
          <Circular style={{ backgroundColor: status?.color }} />
          <TextLabelRes>Cartão {status?.label}</TextLabelRes>
        </CardStatus>
      </CardLabel>
      <CardLabel>
        <TextLabel>limite do cartão:</TextLabel>
        {currentAccount?.creditLimitNac ? (
          <TextLabelRes>{limitFormated}</TextLabelRes>
        ) : (
          <TextLabelRes>Limite indisponível no momento.</TextLabelRes>
        )}
      </CardLabel>
      <ProgressContainer>
        <CurrentProgress style={{ width: `${currentPercentage}%` }} />
        <RemainderProgress style={{ width: `${remainderPercentage}%` }} />
      </ProgressContainer>
      <CardLabel>
        <TextLabel>Utilizado até o momento:</TextLabel>
        {creditUsed || creditUsed === '0' ? (
          <TextLabelRes>{creditUsed}</TextLabelRes>
        ) : (
          <TextLabelRes>Valor utilizado indisponível no momento.</TextLabelRes>
        )}
      </CardLabel>
    </CardInfo>
  );
}

const mapStateToProps = (state: any) => ({
  currentCard: state.relationship.currentCard,
  currentLimitCard: state.relationship.currentLimitCard,
  currentAccount: state.relationship.currentAccount,
});
export default connect(mapStateToProps)(CardInfoComponent);
