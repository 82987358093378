import styled from 'styled-components'

export const Container = styled.div`
    width: 100%;
    background-color: var(--gray-008);
    display: flex;
    align-items: left;
    flex-direction: column;
    margin-bottom: 15px;
`

export const Input = styled.input`
    border-color: transparent;
    border-width: 0px;
    border: none;
    height: 40px;
    width: 100%;
    outline: none;
    padding-left: 10px;
    color: var(--gray-001);
    border-radius: 20px;
`

export const Title = styled.label`
    padding-left: 5px;
    color: var(--gray-001);
    font-size: 14px;
    margin-bottom: 4px;
    font-family: Helvetica-Medium;
    background: var(--gray-008);
`
