import requester from '../requester';

const serviceCompany = {
  companys: {
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: '/v1/corporate/companies',
    headers: {
      environmentName: process.env.REACT_APP_ENVIRONMENT_NAME,
      systemName: process.env.REACT_APP_SYSTEM_NAME,
      Authorization: '{{token}}',
      orgNumber: process.env.REACT_APP_ORG_NUMBER,
    },
    method: 'get',
  },
  updateCompany: {
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: '/v0/portal/empresa/{{cnpj}}',
    headers: {
      'Content-Type': 'application/json',
      environmentName: process.env.REACT_APP_ENVIRONMENT_NAME,
      systemName: process.env.REACT_APP_SYSTEM_NAME,
      Authorization: '{{token}}',
    },
    method: 'put',
  },
  deleteCompany: {
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: '/v0/portal/empresa/{{cnpj}}',
    headers: {
      'Content-Type': 'application/json',
      environmentName: process.env.REACT_APP_ENVIRONMENT_NAME,
      systemName: process.env.REACT_APP_SYSTEM_NAME,
      Authorization: '{{token}}',
    },
    method: 'DELETE',
  },
  createCompany: {
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: process.env.REACT_APP_ENDPOINT_CREATE_COMPANY,
    headers: {
      'Content-Type': 'application/json',
      environmentName: process.env.REACT_APP_ENVIRONMENT_NAME,
      systemName: process.env.REACT_APP_SYSTEM_NAME,
      Authorization: '{{token}}',
    },
    method: 'post',
  },
};

function getToken() {
  const authenticateJson = localStorage.getItem('persist:Fidis:');
  const authenticate = JSON.parse(authenticateJson || '');
  const { token } = JSON.parse(authenticate.authenticate || '');

  return token;
}

export async function getCompanys() {
  let { companys }: { companys: any } = serviceCompany;
  companys.headers.Authorization = getToken();

  const { data } = await requester(companys);
  return data;
}

export async function putUpateCompany(data: any) {
  let { updateCompany }: { updateCompany: any } = serviceCompany;
  updateCompany.headers.Authorization = getToken();
  const { cnpj, razaoSocial, nomeFantasia, status } = data;

  const endpoint = updateCompany.endpoint.replace('{{cnpj}}', cnpj);
  const headers = { ...updateCompany, endpoint };

  const response = await requester(headers, {
    razaoSocial,
    nomeFantasia,
    status,
  });
  return response;
}

export async function deleteCompany(cnpj: string) {
  let { deleteCompany }: { deleteCompany: any } = serviceCompany;
  deleteCompany.headers.Authorization = getToken();

  const endpoint = deleteCompany.endpoint.replace('{{cnpj}}', cnpj);
  const headers = { ...deleteCompany, endpoint };

  const response = await requester(headers);
  return response;
}

export async function postCreateCompany(body: {
  nomeFantasia: string;
  razaoSocial: string;
  cnpj: string;
}) {
  let { createCompany }: { createCompany: any } = serviceCompany;
  createCompany.headers.Authorization = getToken();

  const response = await requester(createCompany, body);
  return response;
}
