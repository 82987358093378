import React from 'react';
import {
  Container,
  Title,
  Description,
  Label,
  Top,
  Middle,
  Bottom,
} from './SignIn.styles';
import { Link } from 'react-router-dom';

import InputIcon from '../../components/InputIcon';
import Button from '../../components/Button';
import Loading from 'components/Loading/Loading.component';

import userIcon from '../../assets/icons/user.png';
import lockIcon from '../../assets/icons/lock.png';

const Login = ({ handlers }: { handlers: any }) => {
  const {
    handleSignIn,
    username,
    password,
    setUsername,
    setPassword,
    loading,
  } = handlers;

  const description = `Seja bem-vindo ao portal de gestão de Cartões\nCorporativos do Banco Fidis.`;

  return (
    <Container>
      <Top>
        <Title>Login</Title>
        <Description>{description}</Description>
      </Top>
      <Middle>
        <InputIcon
          value={username}
          onChange={setUsername}
          icon={userIcon}
          label="Usuário"
          placeholder="Digite o nome de usuário"
        />
        <InputIcon
          value={password}
          onChange={setPassword}
          type="password"
          icon={lockIcon}
          label="Senha"
          placeholder="Digite sua senha"
        />
      </Middle>
      <Bottom>
        {loading ? (
          <Loading size={20} />
        ) : (
          <>
            <Button onClick={handleSignIn}>ENTRAR</Button>
            <Link
              style={{
                textDecoration: 'none',
                color: 'var(--primary);',
                textAlign: 'center',
                marginTop: '5px',
              }}
              to="/forgot_password"
            >
              <Label>Esqueceu sua senha?</Label>
            </Link>
          </>
        )}
      </Bottom>
    </Container>
  );
};

export default Login;
