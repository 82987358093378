import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  padding-left: 10px;
`;

export const Top = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Middle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;

  @media (max-width: 576px) {
    padding: 20px 0;
  }
`;

export const Bottom = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.label`
  font-size: 42px;
  color: var(--primary);
  margin-bottom: 10px;
  font-family: Nexa-XBold;

  @media (max-width: 700px) {
    text-align: center;
  }

  @media (max-width: 576px) {
    font-size: 32px;
  }
`;
export const Description = styled.label`
  font-size: 18px;
  padding: 20px 0;
  color: var(--gray-001);
  text-align: left;
  font-family: 'Helvetica Neue LT Std', Helvetica, Arial, serif;

  @media (max-width: 576px) {
    padding: 10px 0;
  }
`;

export const Text = styled.h5`
  font-family: 'Helvetica Neue LT Std';
`;
