import styled, { createGlobalStyle, css } from "styled-components";

export const Container = styled.div`
  height: 45px;
  width: 100%;
  background-color: #f5f6fa;
  border-radius: 20px;
  display: flex;
  align-items: center;
  flex-direction: row;
`;

export const Input = styled.input`
  border-color: transparent;
  background-color: #f5f6fa;
  border-width: 0px;
  border: none;
  height: 25px;
  width: 90%;
  outline: none;
  padding-left: 10px;
  color: var(--gray-001);
  border-radius: 20px;
`;

export const Title = styled.h5`
  padding-left: 5px;
  color: var(--gray-001);
  font-size: 10px;
  margin-bottom: 4px;
  font-family: "Helvetica Neue LT Std", Helvetica, Arial, serif;
`;
