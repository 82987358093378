import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import HomeScreen from './Home.screen';
import { ISubAccount, ICurrentAccount } from './Home.interface';
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';
import {
  setCurrentAccount,
  setCurrentCard,
  setAccounts,
  setCurrentPage,
} from 'store/modules/relationship/relationship.actions';
import {
  getCardByDocumentNumber,
  getDataAccount,
  nextPage,
  getAccounts
} from 'store/modules/relationship/relationship.endpoints';
import { consultCard, getAccountCards } from 'store/modules/companyCards/companyCards.endpoints';
import { setConsultCard } from 'store/modules/companyCards/companyCards.actions';
import { cleanDocument } from 'utils/Masks/DocumentMask';

function HomeController({
  accounts,
  companyCnpj,
  currentPage,
  relationCode
}: {
  accounts: any;
  companyCnpj: string;
  currentPage: number;
  relationCode: string;
}) {
  const [currentAccounts, setCurrentAccounts] = useState(accounts);
  const [filteredAccounts, setFilteredAccounts] = useState(accounts);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [error, setError] = useState('');
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    getInitialsAccounts();
  }, []);

  // Atualiza o filteredAccounts sempre
  // que o currentAccounts for alterado
  useEffect(() => {
    setFilteredAccounts(currentAccounts);
  }, [currentAccounts]);

  async function getInitialsAccounts() {
    dispatch(setCurrentPage(1))
    setCurrentAccounts(await getAccounts(companyCnpj, relationCode))
    setLoading(false);
  }

  const searchAccount = async () => {
    if (!search) {
      return getInitialsAccounts()
    }

    try {
      // Pesquisa cartão pelo CPF
      const plainDoc = cleanDocument(search);
      const respCard: any = await getCardByDocumentNumber(plainDoc);
      const cards = respCard.data.cardsResult;

      // Verifica a logo do cartão



      if (cards.length > 1) {

        const promiseAll = cards
          .filter((card: any) => ((card.numLogo === 1 || card.numLogo === 2) && card.relationCode === relationCode))
          .map((card: any) =>
            getAccountCards(card.uuid),
          );

        const allAccountCardsResponse = await Promise.all(promiseAll).then(
          (responseAll) => responseAll,
        )

        const currentCardState: any[] = [];

        allAccountCardsResponse.forEach((accountCard: any) => {
          let currentCard: any = {}

          const { cardsResult } = accountCard.data;

          cardsResult.forEach((cardResult: any) => {

            if (cardResult.cardType === 'VIRTUAL') return;

            if (cardResult.cardType === 'TITULAR' && cardResult.blockCode === '') {
              currentCard.last4Digits = cardResult.last4digits
              currentCard.last4DigitsVirtual = cardResult.last4DigitsVirtual
              currentCard.numLogo = cardResult.logo
              currentCard.numOrg = cardResult.org
              currentCard.status = cardResult.status === 'Actived' ? 'active' : 'inactive'
              currentCard.cardStatus = cardResult.status === 'Actived' ? 'active' : 'inactive'
              currentCard.uuid = cardResult.uuid
              currentCard.cardBlockingCode = cardResult.blockCode === '' ? '0' : cardResult.blockCode
              currentCard.accountBlockingCodeTwo = ""
              currentCard.accountWarningCode = cardResult.blockCode === '' ? '0' : cardResult.blockCode
              return;
            }

          });
          currentCardState.push(currentCard);
        });


        const card: any = currentCardState.filter(card => Object.keys(card).length !== 0)[0]
        card.documentNumber = plainDoc

        // Pesquisa dados da conta pelo cartão
        const respAccount: any = await getDataAccount(card.uuid);
        const account = respAccount.data;

        card.cardHolder = account.clientNameHolder;

        const data = [
          card
        ];


        setFilteredAccounts({
          ...currentAccounts,
          totalSubAccounts: 1,
          subAccounts: data
        });

        setLoading(false);
      } else {
        const card = cards[0]

        // Verifica a relação do cartao com a empresa
        if (card.relationCode !== relationCode) {
          setFilteredAccounts({
            ...currentAccounts,
            totalSubAccounts: 0,
            subAccounts: []
          });
          alert('Usuário não encontrado')
          return setLoading(false);
        }

        // Verifica a logo do cartão
        if (card.numLogo !== 1 && card.numLogo !== 2) {
          setFilteredAccounts({
            ...currentAccounts,
            totalSubAccounts: 0,
            subAccounts: []
          });
          alert('Usuário não encontrado')
          return setLoading(false);
        }

        const allAccountCardsResponse = await getAccountCards(card.uuid)

        allAccountCardsResponse.data.cardsResult.forEach((cardResult: any) => {

          if (cardResult.cardType === 'VIRTUAL') return;

          if (cardResult.cardType === 'TITULAR') {
            card.last4Digits = cardResult.last4digits
            card.last4DigitsVirtual = cardResult.last4DigitsVirtual
            card.numLogo = cardResult.logo
            card.numOrg = cardResult.org
            card.status = cardResult.status === 'Actived' ? 'active' : 'inactive'
            card.cardStatus = cardResult.status === 'Actived' ? 'active' : 'inactive'
            card.uuid = cardResult.uuid
            card.cardBlockingCode = cardResult.blockCode === '' ? '0' : cardResult.blockCode
            card.accountBlockingCodeTwo = ""
            card.accountWarningCode = cardResult.blockCode === '' ? '0' : cardResult.blockCode
            return;
          }

        });


        // Pesquisa dados da conta pelo cartão
        const respAccount: any = await getDataAccount(card.uuid);
        const account = respAccount.data;

        card.cardHolder = account.clientNameHolder;

        const data = [
          card
        ];

        setFilteredAccounts({
          ...currentAccounts,
          totalSubAccounts: 1,
          subAccounts: data
        });

        setLoading(false);
      }
    } catch (err) {
      setError('Ocorreu um erro ao buscar os cartões');
      setLoading(false);
    }

  };

  const findUuid = (currentCard: any) => {
    const cardInformation = currentCard.cardsResult.find((card: any) => {
      const { last4Digits2Way, last4Digits, last4DigitsVirtual } = card;
      if (last4Digits2Way == currentCard.last4Digits) {
        return card;
      }
      if (last4Digits == currentCard.last4Digits) {
        return card;
      }
      if (last4DigitsVirtual == currentCard.last4Digits) {
        return card;
      }
    });
    return cardInformation.uuid;
  };

  const handleDetails = async (currentAccount: ICurrentAccount) => {
    const { documentNumber, last4Digits } = currentAccount;

    const { data } = await getCardByDocumentNumber(documentNumber);

    let currentCard = { ...data, last4Digits };

    const uuid = findUuid(currentCard);

    currentCard = { ...currentCard, uuid };
    dispatch(setCurrentCard(currentCard));

    const response = await getDataAccount(currentCard?.uuid);

    const cellPhone = parseInt(response.data.cellPhone);
    const phoneNumber = parseInt(response.data.phoneNumber);
    const dddCellPhone = parseInt(response.data.dddCellPhone);
    const ddd = parseInt(response.data.ddd);
    const newAccount = {
      ...response.data,
      cellPhone,
      phoneNumber,
      dddCellPhone,
      ddd,
      documentNumber,
    };

    dispatch(setCurrentAccount(newAccount));

    const consult = await consultCard(uuid, last4Digits);

    dispatch(setConsultCard(consult.data));

    return history.push('/home/details');
  };

  const handleSearch = async () => {
    setLoading(true);
    await searchAccount();
  };

  const sortDescCardHolder = () => {
    const sorted = currentAccounts.subAccounts
      .slice()
      .sort((a: ISubAccount, b: ISubAccount) =>
        a.cardHolder > b.cardHolder ? 1 : -1
      );
    let subAccountStance = { ...currentAccounts };
    subAccountStance.subAccounts = sorted;

    setCurrentAccounts(subAccountStance);
  };

  const sortAscCardHolder = () => {
    const sorted = currentAccounts.subAccounts
      .slice()
      .sort((a: ISubAccount, b: ISubAccount) =>
        a.cardHolder < b.cardHolder ? 1 : -1
      );

    let subAccountStance = { ...currentAccounts };
    subAccountStance.subAccounts = sorted;

    setCurrentAccounts(subAccountStance);
  };

  const handleNextPage = async () => {
    const startAccount =
      currentAccounts.subAccounts[currentAccounts.subAccounts.length - 1];

    const response = await nextPage({
      companyCnpj,
      startAccount: startAccount.account,
      direction: 'N',
    });

    dispatch(setAccounts(response));
    setCurrentAccounts(response);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    return dispatch(setCurrentPage(currentPage + 1));
  };

  const handlePreviousPage = async () => {
    const page = currentPage - 1
    const startAccount = page !== 1 ? currentAccounts.subAccounts[0].account : "";

    const response = await nextPage({
      companyCnpj,
      startAccount: startAccount,
      direction: 'P',
    });

    dispatch(setAccounts(response));
    setCurrentAccounts(response);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    return dispatch(setCurrentPage(page));
  };

  const handlers = {
    handleDetails,
    accounts: filteredAccounts,
    loading,
    search,
    setSearch,
    error,
    handleSearch,
    sortDescCardHolder,
    sortAscCardHolder,
    handlePreviousPage,
    handleNextPage,
  };

  return <HomeScreen handlers={handlers} />;
}

const mapStateToProps = (state: any) => ({
  accounts: state.relationship.accounts,
  currentPage: state.relationship.currentPage,
  companyCnpj: state.company.cnpj,
  relationCode: state.company.relationCode,
});
export default connect(mapStateToProps)(HomeController);
