import React, { useState, useEffect } from 'react';
import ForgotPasswordScreen from './ForgotPassword.screen';
import { useHistory } from 'react-router-dom';
import {
  getTokenOrbital,
  postForgotPassword,
  postFirstAccess,
} from 'store/modules/authenticate/authenticate.endpoints';
import { setToken } from 'store/modules/authenticate/authenticate.actions';
import { useDispatch } from 'react-redux';

function ForgotPasswordController() {
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState('');
  const [code, setCode] = useState('');
  const [codeValidated, setCodeValidated] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);
  let history = useHistory();
  const dispatch = useDispatch();

  const handleSendCode = async () => {
    try {
      if (!username) {
        return alert('O Campo usuário é obrigatório.');
      }
      setLoading(true);
      const { tokenBackEnd } = await getTokenOrbital();
      const token = `Bearer ${tokenBackEnd.OAuth2AccessToken.access_token}`;
      dispatch(setToken(token));
      await postForgotPassword({ login: username });
      setLoading(false);
      setShowConfirmation(true);
    } catch (error) {
      alert('Usuário não encontrado!');
      setLoading(false);
    }
  };

  const handleConfirmCode = async () => {
    try {
      if (!username || !code) {
        return alert('Preencha todos os campos obrigatórios.');
      }
      setLoading(true);
      await postForgotPassword({ login: username, confirmacao: code });
      setLoading(false);
      setCodeValidated(true);
    } catch (error) {
      alert('Código de confirmação inválido!');
      setLoading(false);
    }
  };

  const handleChangePassword = async () => {
    try {
      if (!password || !confirmPassword) {
        return alert('Todos os campos precisam ser preenchidos!');
      }
      if (password !== confirmPassword) {
        return alert('As senhas precisam ser iguais.');
      }
      setLoading(true);
      await postFirstAccess({
        login: username,
        novaSenha: password,
        confirmarNovaSenha: confirmPassword,
      });
      setLoading(false);
      alert('Senha alterada com sucesso!');
      history.push('/');
    } catch (error) {
      alert('Ocorreu um erro ao alterar a senha.');
      setLoading(false);
    }
  };

  const handleSwitchFunctions = () => {
    if (showConfirmation && !codeValidated) {
      return handleConfirmCode();
    }
    if (!showConfirmation && !codeValidated) {
      return handleSendCode();
    }
    if (showConfirmation && codeValidated) {
      return handleChangePassword();
    }
  };

  const handlers = {
    handleSendCode,
    username,
    code,
    setUsername,
    setCode,
    loading,
    codeValidated,
    setCodeValidated,
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    showConfirmation,
    handleConfirmCode,
    handleChangePassword,
    handleSwitchFunctions,
  };

  return <ForgotPasswordScreen handlers={handlers} />;
}

export default ForgotPasswordController;
