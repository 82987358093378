import styled from 'styled-components'

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 70px;
    background: var(--gray-008);

    @media(max-width: 768px){
        display: none;
    }
`
export const MainNavigation = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    flex-direction: row;
`

export const Left = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
    align-items: center;
`
export const Right = styled.div`
    margin-left:20px;
    display: flex;
    flex-direction: row;
    flex: 7;
    align-items: center;

`

export const CurrentPage = styled.div`
    display: flex;
    width: 78%;
    height: auto;
    background: var(--gray-008);
    font-size: 1.4rem;
    color: #00a4a1;
    font-family: 'Helvetica-Medium';
`
export const TitleBack = styled.h5`
    color: var(--gray-001);
    font-family: "Helvetica-Medium";
    padding-top:4px;
`
export const Pipeline = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 30px;
    border-left: 2px solid var(--gray-001);
`
export const IconHome = styled.img`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    width: 20px;
    padding: 2px;
`
export const IconChevrons = styled.img`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    width: 15px;
    padding: 2px;
`

export const DivLine = styled.div`
    display: flex;
    background-color: var(--gray-006);
    height: 2px;
    width: 100%;
    opacity: 1;
`

export const IconPrevious = styled.img`
    height: 10px;
    margin-right: 20px;
`

export const BackPage = styled.div`
    display: flex;
    flex: 1;
    height:20px;
    border-right: 2px solid var(--gray-006);
    align-items: center;
    cursor: pointer;
    flex-direction: row;
`

export const Title = styled.h5`
    color: var(--primary);
    margin-right: 20px;
    align-items: center;
    font-family: "Helvetica-Medium";
    padding-top:4px;
`

