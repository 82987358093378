import styled, { createGlobalStyle, css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  padding: 10px 0;

  @media (max-width: 992px) {
    flex-direction: column;
  }
`;

export const Left = styled.div`
  flex: 5;

  @media (max-width: 576px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
export const Middle = styled.div`
  flex: 1;
`;

export const Right = styled.div`
  flex: 5;

  @media (max-width: 992px) {
    padding: 20px 0;
  }
  @media (max-width: 576px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const Divider = styled.div`
  height: 30vw;
  width: 2px;
  background-color: var(--gray-005);
  @media (max-width: 992px) {
    width: 100%;
    height: 2px;
    margin-top: 10px;
  }
`;

export const Title = styled.label`
  font-family: Helvetica-Medium;
  color: var(--gray-001);
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 23px;

  @media (max-width: 576px) {
    font-size: 16px;
  }
`;

export const Label = styled.label`
  font-family: 'Helvetica-Normal';
  color: var(--gray-001);
  font-size: 14px;
  margin-bottom: 5px;
`;
export const LabelMedium = styled.label`
  font-family: 'Helvetica-Medium';
  color: var(--gray-001);
  font-size: 14px;
`;
