import React from 'react';
import {
  Container,
  Blocked,
  Title,
  Label,
  Button,
} from './PasswordUnlock.styles';
import Loading from 'components/Loading/Loading.component';

function PasswordUnlockScreen({ handlers }: { handlers: any }) {
  let { handlePasswordUnlock, loading, permanentBlock } = handlers;
  return (
    <Container>
      {permanentBlock ? (
        <Blocked>
          <Title>Desbloqueio de senha indisponível - Cartão bloqueado</Title>
        </Blocked>
      ) : (
        <>
          <Title>Desbloqueio de senha</Title>
          <Label>Confirmar desbloqueio de senha?</Label>
          {loading ? (
            <Loading size={20} />
          ) : (
            <Button onClick={handlePasswordUnlock}>Desbloquear Senha</Button>
          )}
        </>
      )}
    </Container>
  );
}

export default PasswordUnlockScreen;
