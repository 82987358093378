import React from 'react';
import {
  ImageBackground,
  Container,
  Card,
  Form,
  Image,
  Logo,
  LogoBlack,
  Title,
  Label,
} from './styles';
import PublicRoutes from '../../routes/Public';
import logo2 from 'assets/images/logoGreenBlack.png';
import logo from 'assets/images/logo.png';

const Login = () => {
  return (
    <Container>
      <ImageBackground>
        <LogoBlack src={logo2} />
        <Card>
          <Image>
            <Logo src={logo} />
          </Image>
          <Form>
            <PublicRoutes />
          </Form>
        </Card>
        <Title>
          Gestão de <Label>Cartões Corporativos</Label>
        </Title>
      </ImageBackground>
    </Container>
  );
};

export default Login;
