import React from 'react';
import { Route, Switch } from 'react-router-dom';

import CarrierData from '../../containers/CarrierData/CarrierData.controller';
import DefinitiveBlock from '../../containers/DeactiveCard/deactiveCard.controller';
import Request2nd from '../../containers/Request2nd/Request2nd.controller';
import PasswordUnlock from '../../containers/PasswordUnlock/PasswordUnlock.controller';
import CancelCard from '../../containers/CancelCard/CancelCard.controller';
import ManageLimit from '../../containers/ManageLimit/ManageLimit.controller';
import AuthorizationHistory from '../../containers/AuthorizationHistory/AuthorizationHistory.controller';

function CardsRoute() {
  return (
    <Switch>
      <Route path="/home/details/block" component={DefinitiveBlock} />
      <Route path="/home/details/request2nd" component={Request2nd} />
      <Route path="/home/details/unlock" component={PasswordUnlock} />
      <Route path="/home/details/cancel" component={CancelCard} />
      <Route path="/home/details/limit" component={ManageLimit} />
      <Route path="/home/details/history" component={AuthorizationHistory} />
      <Route component={CarrierData} />
    </Switch>
  );
}

export default CardsRoute;
