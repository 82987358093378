export const routes: any = {
  '/home': 'Cartões da empresa',
  '/home/details': 'Cartões da empresa',
  '/home/details/block': 'Cartões da empresa',
  '/home/details/request2nd': 'Cartões da empresa',
  '/home/details/unlock': 'Cartões da empresa',
  '/home/details/limit': 'Cartões da empresa',
  '/home/details/history': 'Cartões da empresa',
  '/home/details/cancel': 'Cartões da empresa',
  '/home/invoices': 'Faturas do Relacionamento',
  '/home/requestcard': 'Solicitar Cartão',
  '/home/user/management': 'Gestão de Usuários',
  // '/home/company/management': 'Gestão de Empresas',
};
