import React from 'react';
import { DivLine, Container } from './Home.styles';
import ListCards from './components/ListCards/ListCards.component';
import Header from './components/Header/Header.component';
import Loading from 'components/Loading/Loading.component';

const HomeScreen = ({ handlers }: { handlers: any }) => {
  const { handleDetails, accounts, loading } = handlers;
  return (
    <Container>
      <Header handlers={handlers} />
      <DivLine />
      {loading ? (
        <Loading size={40} icon={true} />
      ) : (
        <ListCards handlers={handlers} />
      )}
    </Container>
  );
};

export default HomeScreen;
