import React from 'react';
import { Container, CardImage } from './CreditCard.styles';

function CreditCardComponent({
  card,
  height,
}: {
  card: string;
  height?: string;
}) {
  return (
    <Container>
      <CardImage height={height} src={card} />
    </Container>
  );
}

export default CreditCardComponent;
