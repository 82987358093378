import React, { useState, useEffect } from 'react';
import CreateUserScreen from './CreateUser.screen';
import { getCompanys } from 'store/modules/company/company.endpoints';
import { postCreateUser } from 'store/modules/client/client.endpoints';
import { cpf as CpfValidator } from 'cpf-cnpj-validator';
import * as EmailValidator from 'email-validator';
import history from 'services/history';
import { PhoneMask } from 'utils/Masks/PhoneMask';
import { DocumentMask, cleanDocument } from 'utils/Masks/DocumentMask';

function CreateUserController() {
  const [numeroDocumento, setNumeroDocumento] = useState('');
  const [login, setLogin] = useState('');
  const [nomeUsuario, setNomeUsuario] = useState('');
  const [email, setEmail] = useState('');
  const [numCelular, setNumCelular] = useState('');
  const [ddd, setDdd] = useState('');
  const [ddi, setDdi] = useState('');
  const [listaPerfis, setListaPerfis] = useState<any>(['']);
  const [companys, setCompanys] = useState<any>([]);
  const [currentCompany, setCurrentCompany] = useState('');
  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //     findCompany()
  // }, [])

  // const findCompany = async () =>  {
  //     try {
  //     const result = await getCompanys()
  //     const companys = result.empresas
  //     const newObjectCompany = companys.map((company:any)=>{
  //         return {title:company.razaoSocial,value:company.cnpj}
  //     })
  //     setCompanys(newObjectCompany)
  //     } catch (error) {
  //         console.log("error on findCompany",error)
  //     }
  // }

  const handleCreateUser = async () => {
    try {
      const cpfIsValid = CpfValidator.isValid(numeroDocumento);
      const emailIsValid = EmailValidator.validate(email);
      if (
        !login ||
        !numCelular ||
        !nomeUsuario ||
        !numeroDocumento ||
        !ddd ||
        !ddi ||
        !email
      ) {
        return alert('Todos os campos são obrigatórios.');
      }
      if (!cpfIsValid) {
        return alert(
          'O Número de documento é invalido, preencha o campo corretamente.'
        );
      }
      if (!emailIsValid) {
        return alert('O E-mail é invalido, preencha o campo corretamente.');
      }

      setLoading(true);
      await postCreateUser({
        login,
        numCelular: parseInt(numCelular.split('-').join(''), 10),
        nomeUsuario,
        numeroDocumento: cleanDocument(numeroDocumento),
        ddd: parseInt(ddd, 10),
        ddi: parseInt(ddi, 10),
        listaPerfis: [''],
        email,
      });
      setLoading(false);
      history.push('/home/user/management/manage');
      alert('Usuário cadastrado com sucesso!');
    } catch (error) {
      alert(error?.response?.data?.mensagemRetorno);
      setLoading(false);
    }
  };

  const handlePhoneNumber = (text: string) => {
    setNumCelular(PhoneMask(text));
  };

  const handleDocument = (text: string) => {
    setNumeroDocumento(DocumentMask(text));
  };

  const handlers = {
    numeroDocumento,
    setNumeroDocumento,
    login,
    setLogin,
    nomeUsuario,
    setNomeUsuario,
    email,
    setEmail,
    numCelular,
    handlePhoneNumber,
    ddd,
    setDdd,
    ddi,
    setDdi,
    listaPerfis,
    setListaPerfis,
    companys,
    setCompanys,
    currentCompany,
    setCurrentCompany,
    loading,
    handleCreateUser,
    handleDocument,
  };

  return <CreateUserScreen handlers={handlers} />;
}

export default CreateUserController;
