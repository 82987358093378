import React from 'react';
import { Container, TitleArea, Title } from './ManageUser.style';
import ListUsers from './components/ListUsers/ListUsers.component';
import Fields from './components/Fields/Fields.component';

function ManageUserScreen({ handlers }: { handlers: any }) {
  let { edit } = handlers;
  return (
    <Container>
      <TitleArea>
        <Title>Gerenciar Usuários</Title>
      </TitleArea>
      {edit ? (
        <Fields handlers={handlers} />
      ) : (
        <ListUsers handlers={handlers} />
      )}
    </Container>
  );
}

export default ManageUserScreen;
