import React from 'react';
import {
  Container,
  Left,
  CreditCardArea,
  Right,
  Middle,
  CreditCardGreen,
  CreditCardBlack,
} from './SelectCard.styles';
import { ReactComponent as Check } from '../../../../assets/svg/Check.svg';

function SelectCardComponent({ handlers }: { handlers: any }) {
  let { creditCard, handleCreditCard } = handlers;

  return (
    <Container>
      <Left>
        <CreditCardArea>
          <CreditCardBlack
            onClick={() => handleCreditCard('black')}
            style={{ opacity: creditCard == 'black' || !creditCard ? 1 : 0.4 }}
          >
            {creditCard === 'black' && <Check />}
          </CreditCardBlack>
        </CreditCardArea>
      </Left>
      <Right>
        <CreditCardGreen
          onClick={() => handleCreditCard('green')}
          style={{ opacity: creditCard == 'green' || !creditCard ? 1 : 0.4 }}
        >
          {creditCard === 'green' && <Check />}
        </CreditCardGreen>
      </Right>
    </Container>
  );
}

export default SelectCardComponent;
