import axios from 'axios';

const requester = async (service: any, data = {}) => {
  let { baseURL, endpoint, headers = {}, method } = service;

  const response = await axios({
    method: method,
    url: baseURL + endpoint,
    headers,
    data,
  });

  console.log('RESPONSE REQUESTER: ', response);

  return response;
};

export default requester;
