import React from 'react';
import {
  Container,
  Left,
  Middle,
  Right,
  Title,
  SelectButton,
} from './Header.styles';
import InputSource from '../../../../components/InputSource';

function HeaderComponent({ handlers }: { handlers: any }) {
  const {
    accounts,
    search,
    setSearch,
    handleSearch,
    sortDescCardHolder,
    sortAscCardHolder,
  } = handlers;

  function handleSort(option: string) {
    if (option === 'desc') {
      return sortDescCardHolder();
    }

    return sortAscCardHolder();
  }

  return (
    <Container>
      <Left>
        <Title>{accounts?.meta.totalRecords} cartões cadastrados</Title>
      </Left>

      <Middle>
        <InputSource
          value={search}
          setSearch={setSearch}
          handleSearch={handleSearch}
        />
      </Middle>
      <Right>
        <SelectButton onChange={(option) => handleSort(option.target.value)}>
          <option value="desc">A - Z</option>
          <option value="asc">Z - A</option>
        </SelectButton>
      </Right>
    </Container>
  );
}

export default HeaderComponent;
