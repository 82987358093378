import React, { useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import SideBar from '../../components/SideBar';
import UserRoute from '../../routes/User';
import Header from './components/Header/Header.component';
import { Row, Left, Middle, Container, Footer, LogoIcon } from './styles';
import { routes } from './routes';
import logoFooter from 'assets/images/logoGray.png';
import Drawer from 'components/Drawer/Drawer.component';
import { connect } from 'react-redux';

const UserLayout = ({ companyName }: { companyName: string }) => {
  const [drawer, setDrawer] = useState(false);

  const location = useLocation();
  const path: any = location.pathname;

  const title = routes[path];

  const componentRef: any = useRef();

  const handlers = {
    setDrawer,
    drawer,
  };

  return (
    <Container ref={componentRef}>
      <Header handlers={handlers} title={title} companyName={companyName} />
      <Drawer handlers={handlers} />
      <Row>
        <Left>
          <SideBar />
        </Left>
        <Middle>
          <UserRoute />
        </Middle>
      </Row>
      <Footer>
        <LogoIcon src={logoFooter} />
      </Footer>
    </Container>
  );
};

const mapStateToProps = (state: any) => ({
  companyName: state.company.name,
});
export default connect(mapStateToProps)(UserLayout);
