import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  padding-left: 10px;
  justify-content: space-between;
`;
export const Top = styled.div`
  text-align: center;
`;

export const Middle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const InputArea = styled.div`
  width: 100%;
`;

export const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-direction: column;
  padding: 10px 0;
`;

export const Title = styled.h1`
  font-size: 28px;
  color: var(--primary);
  margin-bottom: 10px;
  font-family: Nexa-XBold;
`;
export const Description = styled.label`
  color: var(--gray-001);
  text-align: left;
  font-family: 'Helvetica-Normal';

  @media (max-width: 576px) {
    font-size: 16px;
  }
`;

export const Text = styled.h5`
  font-family: 'Helvetica Neue LT Std';
`;

export const Label = styled.label`
  font-family: 'Helvetica Neue LT Std', Helvetica, Arial, serif;
  font-size: 1.4rem;
  text-align: end;
  color: var(--primary);
  cursor: pointer;
`;

export const Button = styled.button`
  width: 90%;
  height: 35px;
  border-radius: 30px;
  background-color: var(--primary);
  border: none;
  color: #fff;
  font-family: 'Helvetica-Medium';
  margin: 10px 0;
  &:hover {
    background-color: var(--primary);
  }
  &:active {
    background-color: var(--primary);
  }
`;
