import React, { useEffect, useState } from 'react';
import { listMenu as listMenuData } from './routes';

import { useHistory } from 'react-router-dom';

import {
  MenuBox,
  MenuIcon,
  Title,
  Row,
  Left,
  Right,
  Container,
} from './styles';

interface IMenu {
  active: boolean;
  icon: string;
  iconGreen: string;
  iconWhite: string;
  link: string;
  title: string;
}

const Menu = ({ listMenu }: { listMenu: Array<IMenu> }) => {
  const [update, setUpdate] = useState(1);
  const [options, setOptions] = useState(listMenu);
  let history = useHistory();
  let corActive = 'var(--primary)';

  const pathname = window.location.pathname;

  useEffect(() => {
    const optionIndex = options.findIndex((option) => {
      return option.link === pathname;
    });

    if (optionIndex >= 0) {
      const optionsInstance = options;

      optionsInstance.map((option) => {
        option.active = false;
      });

      optionsInstance[optionIndex].active = true;

      setOptions([...optionsInstance]);
    }
  }, [pathname]);

  const handleClick = (item: IMenu) => {
    setUpdate(update + 1);
    const optionIndex = options.findIndex((option) => {
      return option.link === item.link;
    });

    if (optionIndex >= 0) {
      const optionsInstance = options;

      optionsInstance.map((option) => {
        option.active = false;
      });

      optionsInstance[optionIndex].active = true;

      setOptions([...optionsInstance]);
      history.push(item.link);
    }
  };
  return (
    <Container>
      {options.map((item: IMenu, index: number) => {
        return (
          <MenuBox
            key={index}
            style={{
              background: item.active ? corActive : 'white',
            }}
            onClick={() => handleClick(item)}
          >
            <Row>
              <Left>
                <MenuIcon
                  key={index}
                  src={item.active ? item.iconWhite : item.iconGreen}
                />
              </Left>
              <Right>
                <Title
                  style={{
                    color: item.active ? 'white' : corActive,
                  }}
                >
                  {item.title}
                </Title>
              </Right>
            </Row>
          </MenuBox>
        );
      })}
    </Container>
  );
};

export default Menu;
