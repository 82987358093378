import requester from '../requester';
import { IAddress } from './client.interface';

const serviceClient = {
  address: {
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: '/v1/client/address/{{uuid}}',
    headers: {
      'Content-Type': 'application/json',
      environmentName: process.env.REACT_APP_ENVIRONMENT_NAME,
      systemName: process.env.REACT_APP_SYSTEM_NAME,
      Authorization: '{{token}}',
    },
    method: 'put',
  },
  findAllClients: {
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: '/v0/portal/usuario/todos?page=0&limit=100&direction=asc',
    headers: {
      'Content-Type': 'application/json',
      environmentName: process.env.REACT_APP_ENVIRONMENT_NAME,
      systemName: process.env.REACT_APP_SYSTEM_NAME,
      Authorization: '{{token}}',
    },
    method: 'get',
  },
  createAccountCorporate: {
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: '/v2/proposal/corporate/{{documentNumber}}',
    headers: {
      'Content-Type': 'application/json',
      environmentName: process.env.REACT_APP_ENVIRONMENT_NAME,
      systemName: 'spark',
      org: '090',
      Authorization: '{{token}}',
    },
    method: 'post',
  },
  updateUser: {
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: '/v0/portal/usuario/{{documentNumber}}',
    headers: {
      'Content-Type': 'application/json',
      environmentName: process.env.REACT_APP_ENVIRONMENT_NAME,
      systemName: process.env.REACT_APP_SYSTEM_NAME,
      Authorization: '{{token}}',
    },
    method: 'patch',
  },
  updateUserStatus: {
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: '/v0/portal/usuario/status/{{documentNumber}}',
    headers: {
      'Content-Type': 'application/json',
      environmentName: process.env.REACT_APP_ENVIRONMENT_NAME,
      systemName: process.env.REACT_APP_SYSTEM_NAME,
      Authorization: '{{token}}',
    },
    method: 'put',
  },
  createUser: {
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: process.env.REACT_APP_ENDPOINT_CREATE_USER,
    headers: {
      'Content-Type': 'application/json',
      environmentName: process.env.REACT_APP_ENVIRONMENT_NAME,
      systemName: process.env.REACT_APP_SYSTEM_NAME,
      Authorization: '{{token}}',
    },
    method: 'post',
  },
  deleteUser: {
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: '/v0/portal/usuario/{{numeroDocumento}}',
    headers: {
      'Content-Type': 'application/json',
      environmentName: process.env.REACT_APP_ENVIRONMENT_NAME,
      systemName: process.env.REACT_APP_SYSTEM_NAME,
      Authorization: '{{token}}',
    },
    method: 'DELETE',
  },
};

function getToken() {
  const authenticateJson = localStorage.getItem('persist:Fidis:');
  const authenticate = JSON.parse(authenticateJson || '');
  const { token } = JSON.parse(authenticate.authenticate || '');

  return token;
}

export async function putAddress(body: IAddress, uuid: string) {
  let { address }: { address: any } = serviceClient;
  address.headers.Authorization = getToken();

  const endpoint = address.endpoint.replace('{{uuid}}', uuid);
  const headers = { ...address, endpoint };

  const response = await requester(headers, body);
  return response;
}

export async function getAllClients() {
  let { findAllClients }: { findAllClients: any } = serviceClient;
  findAllClients.headers.Authorization = getToken();
  const response = await requester(findAllClients);
  return response;
}

export async function postCreateAccountCorporate(body: any) {
  let {
    createAccountCorporate,
  }: { createAccountCorporate: any } = serviceClient;
  createAccountCorporate.headers.Authorization = getToken();

  const endpoint = createAccountCorporate.endpoint.replace(
    '{{documentNumber}}',
    body.documentNumber
  );
  const headers = { ...createAccountCorporate, endpoint };

  const response = await requester(headers, body);
  return response;
}

export async function patchUserAccount(
  documentNumber: string,
  body: {
    email: string;
    ddd: number;
    ddi: number;
    numeroCelular: number;
    nomeUsuario: string;
    listaPerfis: any;
  }
) {
  let { updateUser }: { updateUser: any } = serviceClient;
  updateUser.headers.Authorization = getToken();

  const endpoint = updateUser.endpoint.replace(
    '{{documentNumber}}',
    documentNumber
  );
  const headers = { ...updateUser, endpoint };
  const response = await requester(headers, body);
  return response;
}
export async function updateUserStatus(documentNumber: string, status: string) {
  let { updateUserStatus }: { updateUserStatus: any } = serviceClient;
  updateUserStatus.headers.Authorization = getToken();

  const endpoint = updateUserStatus.endpoint.replace(
    '{{documentNumber}}',
    documentNumber
  );
  const body = {
    status,
  };
  const headers = { ...updateUserStatus, endpoint };
  const response = await requester(headers, body);
  return response;
}

export async function postCreateUser(body: {
  numeroDocumento: string;
  login: string;
  nomeUsuario: string;
  email: string;
  numCelular: number;
  ddd: number;
  ddi: number;
  listaPerfis: any;
}) {
  let { createUser }: { createUser: any } = serviceClient;
  createUser.headers.Authorization = getToken();
  const response = await requester(createUser, body);
  return response;
}
export async function deleteUser(numeroDocumento: string) {
  let { deleteUser }: { deleteUser: any } = serviceClient;

  deleteUser.headers.Authorization = getToken();
  const endpoint = deleteUser.endpoint.replace(
    '{{numeroDocumento}}',
    numeroDocumento
  );

  const headers = { ...deleteUser, endpoint };
  const response = await requester(headers);
  return response;
}
