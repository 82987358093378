import removeGreen from '../../assets/icons/icons_green/remove.png';
import levelGreen from '../../assets/icons/icons_green/level.png';
import cardBlockedGreen from '../../assets/icons/icons_green/cardBlocked.png';
import codeGreen from '../../assets/icons/icons_green/code.png';
import clockGreen from '../../assets/icons/icons_green/clock.png';
import creditGreen from '../../assets/icons/icons_green/creditcards.png';
import carrierGreen from '../../assets/icons/icons_green/carrier.png';
import carrier from '../../assets/icons/icons_white/carrier.png';
import remove from '../../assets/icons/icons_white/remove.png';
import level from '../../assets/icons/icons_white/level.png';
import cardBlocked from '../../assets/icons/icons_white/cardBlocked.png';
import code from '../../assets/icons/icons_white/code.png';
import clock from '../../assets/icons/icons_white/clock.png';
import credit from '../../assets/icons/icons_white/creditcards.png';
import userAddWhite from 'assets/icons/icons_white/userAdd.png';
import userAddGreen from 'assets/icons/icons_green/userAdd.png';
import companyEditWhite from 'assets/icons/icons_white/companyEdit.png';
import companyEditGreen from 'assets/icons/icons_green/companyEdit.png';
import companyMoreWhite from 'assets/icons/icons_white/companyMore.png';
import companyMoreGreen from 'assets/icons/icons_green/companyMore.png';
import chatWhite from 'assets/icons/icons_white/chat.png';
import cardWhite from 'assets/icons/icons_white/card.png';
import searchWhite from 'assets/icons/icons_white/searchIcon.png';
import userWhite from 'assets/icons/icons_white/user.png';
import userGray from 'assets/icons/icons_gray/user.png';
import companyGray from 'assets/icons/icons_gray/company.png';
import companyWhite from 'assets/icons/icons_white/company.png';
import search from 'assets/icons/icons_gray/search/search.png';
import card from 'assets/icons/Grupo9680.png';
import iconbasicuicalendar from 'assets/icons/icons_gray/chat.png';

export const detailsMenu = [
  {
    icon: '',
    title: `Dados do portador`,
    link: '/home/details',
    active: true,
    iconWhite: carrier,
    iconGreen: carrierGreen,
  },
  {
    icon: '',
    title: 'Bloqueio / Desbloqueio',
    link: '/home/details/block',
    active: false,
    iconWhite: cardBlocked,
    iconGreen: cardBlockedGreen,
  },
  {
    icon: '',
    title: 'Solicitar 2ª Via',
    link: '/home/details/request2nd',
    active: false,
    iconWhite: credit,
    iconGreen: creditGreen,
  },
  {
    icon: '',
    title: 'Desbloqueio de senha',
    link: '/home/details/unlock',
    active: false,
    iconWhite: code,
    iconGreen: codeGreen,
  },
  {
    icon: '',
    title: 'Gestão de Limite',
    link: '/home/details/limit',
    active: false,
    iconWhite: level,
    iconGreen: levelGreen,
  },
  {
    icon: '',
    title: 'Transações',
    link: '/home/details/history',
    active: false,
    iconWhite: clock,
    iconGreen: clockGreen,
  },
  {
    icon: '',
    title: 'Cancelar conta cartão',
    link: '/home/details/cancel',
    active: false,
    iconWhite: remove,
    iconGreen: removeGreen,
  },
];

export const menuManageUser = [
  {
    icon: '',
    title: `Criar Usuário`,
    link: '/home/user/management',
    active: true,
    iconWhite: userAddWhite,
    iconGreen: userAddGreen,
  },
  {
    icon: '',
    title: 'Gerenciar Usuários',
    link: '/home/user/management/manage',
    active: false,
    iconWhite: cardBlocked,
    iconGreen: cardBlockedGreen,
  },
];

// export const menuManageCompany = [
//   {
//     icon: '',
//     title: `Gerenciar Empresas`,
//     link: '/home/company/management',
//     active: true,
//     iconWhite: companyEditWhite,
//     iconGreen: companyEditGreen,
//   },
//   {
//     icon: '',
//     title: 'Cadastrar Empresa',
//     link: '/home/company/management/manage',
//     active: false,
//     iconWhite: companyMoreWhite,
//     iconGreen: companyMoreGreen,
//   },
// ];

export const HeaderMenu = [
  {
    title: `Dados do portador`,
    link: '/home/details',
    menu: 'Cartões da empresa',
  },
  {
    title: 'Bloqueio de cartão',
    link: '/home/details/block',
    menu: 'Cartões da empresa',
  },
  {
    title: 'Solicitar 2ª Via',
    link: '/home/details/request2nd',
    menu: 'Cartões da empresa',
  },
  {
    title: 'Desbloqueio de senha',
    link: '/home/details/unlock',
    menu: 'Cartões da empresa',
  },
  {
    title: 'Gestão de Limite',
    link: '/home/details/limit',
    menu: 'Cartões da empresa',
  },
  {
    title: 'Transações',
    link: '/home/details/history',
    menu: 'Cartões da empresa',
  },
  {
    title: 'Cancelar conta cartão',
    link: '/home/details/cancel',
    menu: 'Cartões da empresa',
  },
  // {
  //   title: 'Cadastrar Empresa',
  //   link: '/home/company/management/manage',
  //   menu: 'Gestão de Empresas',
  // },
  // {
  //   title: `Gerenciar Empresas`,
  //   link: '/home/company/management',
  //   menu: 'Gestão de Empresas',
  // },
  {
    title: 'Gerenciar Usuários',
    link: '/home/user/management/manage',
    menu: 'Gestão de Usuários',
  },
  {
    title: `Criar Usuário`,
    link: '/home/user/management',
    menu: 'Gestão de Usuários',
  },
  {
    title: `Fatura`,
    link: '/home/invoices',
    menu: 'Faturas do Relacionamento',
  },
];

export const sidebarRoutes = [
  {
    title: 'Cartões da Empresa',
    link: '/home',
    icon: search,
    iconActivated: searchWhite,
    active: true,
    disabled: false,
  },
  {
    title: 'Faturas do Relacionamento',
    link: '/home/invoices',
    icon: iconbasicuicalendar,
    iconActivated: chatWhite,
    active: false,
    disabled: false,
  },
  {
    title: 'Solicitar Cartão',
    link: '/home/requestcard',
    icon: card,
    iconActivated: cardWhite,
    active: false,
    disabled: false,
  },
  {
    title: 'Gestão de usuários',
    link: '/home/user/management',
    icon: userGray,
    iconActivated: userWhite,
    active: false,
    disabled: false,
  },
  // {
  //   title: 'Gestão de Empresas',
  //   link: '/home/company/management',
  //   icon: companyGray,
  //   iconActivated: companyWhite,
  //   active: false,
  //   disabled: false,
  // },
];
