import produce from 'immer';

export const INITIAL_STATE = {
  name: null,
  cnpj: null,
  relationCode: null,
};

export default function CompanyReducer(state = INITIAL_STATE, action: any) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@company/SET_CURRENT_COMPANY': {
        draft.name = action.company.title;
        draft.cnpj = action.company.value;
        draft.relationCode = action.company.relationCode;
        break;
      }
      default:
    }
  });
}
