import React from 'react'
import {
    InputSourceTag,
    Button,
    DivInputSource,
    SearchIcon,
    TextButton,
} from './styles'

import Search from '../../assets/svg/search.svg'

import { DocumentMask } from '../../utils/Masks/DocumentMask'

const InputSource = ({
    value,
    setSearch,
    handleSearch,
}: {
    value: any;
    setSearch: any;
    handleSearch: any;
}) => {
    
    return (
        <DivInputSource>
            <InputSourceTag
                value={value}
                placeholder="Buscar CPF do portador"
                onChange={(event) =>
                    setSearch(DocumentMask(event.target.value))
                }
                maxLength={14}
                type="text"
            />
            <Button onClick={handleSearch}>
                <TextButton>Consultar</TextButton>
                <SearchIcon src={Search} />
            </Button>
        </DivInputSource>
    )
}

export default InputSource
