import React from 'react';
import {
  Header,
  Descriptions,
  LeftHeader,
  MiddleHeader,
  Label,
  TitleHeader,
  Drawer,
  Left,
  Right,
  LogoIcon,
  Menu,
} from './Header.styles';
import bg from 'assets/images/background.png';
import logo from 'assets/icons/MarcaColor.png';
import menu from 'assets/icons/menu.png';

function HeaderComponent({
  companyName,
  title,
  handlers,
}: {
  companyName: string;
  title: string;
  handlers: any;
}) {
  let { setDrawer } = handlers;

  return (
    <>
      <Drawer>
        <Left>
          <LogoIcon src={logo} />
        </Left>
        <Right>
          <Menu src={menu} onClick={() => setDrawer(true)} />
        </Right>
      </Drawer>
      <Header
        style={{
          background: `transparent url(${bg})`,
          backgroundSize: 'cover',
        }}
      >
        <Descriptions>
          <LeftHeader />
          <MiddleHeader>
            <Label>{companyName}</Label>
            <TitleHeader>{title}</TitleHeader>
          </MiddleHeader>
        </Descriptions>
      </Header>
    </>
  );
}

export default HeaderComponent;
