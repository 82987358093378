import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

export default (reducers) => {
  const persistedReducer = persistReducer(
    {
      key: 'Fidis:',
      storage,
      whitelist: [
        'authenticate',
        'client',
        'company',
        'relationship',
        'companyCards',
      ],
      // transforms: [
      //   expireReducer('user', {
      //     expireSeconds: 604800,
      //     expiredState: { products: [] },
      //     autoExpire: true,
      //   }),
      // ],
    },

    reducers
  );

  return persistedReducer;
};
