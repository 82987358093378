import { combineReducers } from 'redux';
import authenticate from './authenticate/authenticate.reducer';
import company from './company/company.reducer';
import relationship from './relationship/relationship.reducer';
import companyCards from './companyCards/companyCards.reducer';

export default combineReducers({
  authenticate,
  company,
  relationship,
  companyCards,
});
