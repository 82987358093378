import styled from 'styled-components'

export const Container = styled.div`
    width:100%;
    height:40%;
    display:flex;
    flex-direction: column;
    @media (max-width: 768px) {
        flex-direction: row;
    }
`
export const TopTab = styled.div`
    flex:1;
    display:flex;
    flex-direction:row;
    @media (max-width: 768px) {
        flex-direction: column;
    }
`

export const BottomTab = styled.div`
    flex:1;
    display:flex;
    flex-direction:row;
    @media (max-width: 768px) {
        flex-direction: column;
    }
`
export const Left = styled.div`
    flex:4;
    display:flex;
    flex-direction:row;
    justify-content:flex-end;
    align-self:center;
    @media (max-width: 768px) {
        flex-direction:column;
        justify-content:flex-end
        align-items:center;
    }
`
export const Right = styled.div`
    flex:1;
    display:flex;
    flex-direction:column;
    justify-content:flex-end;
`
export const Label = styled.h5`
    font-family: "Nexa-Light";
    font-size: 1.4rem;
    text-align: end;
    color: var(--gray-001);
    cursor: pointer;
    @media (max-width: 768px) {
       text-align:center;
       font-size: 1.1rem;
    }
`

export const ToggleGreen = styled.div<any>`
    margin-left:10px;
    width: 5px;
    height: 100%;
    background-color: ${(props) => props.color};
    cursor: pointer;
    @media (max-width: 768px) {
        margin-left:0px;
        height: 5px;
        width: 100%;
        margin-top:10px;
    }
`