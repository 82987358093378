import React from 'react'
import { Container, Input, Title } from './styles'

function InputLabel({
    placeholder,
    label,
    type,
    onChange,
    value,
    pattern,
    disabled,
    inputStyle,
    number,
    maxLength
}: {
    placeholder?: string;
    label: string;
    type?: string;
    onChange?: any;
    value?: string;
    pattern?: string;
    disabled?:boolean;
    inputStyle?: any;
    number?:boolean;
    maxLength?:any;
}) {
    return (
        <Container>
            <Title>{label}</Title>
            <Input
                maxLength={maxLength}
                style={inputStyle? inputStyle : {backgroundColor:"#fff"}}
                disabled={disabled}
                value={value}
                placeholder={placeholder}
                onChange={(e) => {
                    onChange(number ? e.target.value.replace(/[^0-9.]/g, "") : e.target.value)
                }}
                type={type}
                pattern={pattern}
            />
        </Container>
    )
}

export default InputLabel
