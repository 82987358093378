import styled from 'styled-components';

export const PersonalData = styled.div`
  display: flex;
  width: 15%;
  height: 100%;
  min-height: 600px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: var(--gray-008);
`;

export const CarrierDataInfo = styled.div`
  display: flex;
  width: 46%;
  height: 100%;
  min-height: 600px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 40px 0px 40px;
  background: var(--gray-008);
`;
export const DataPersonal = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 600px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 40px 0px 40px;
  background: var(--gray-008);
`;
export const DataAddress = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 600px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 40px 0px 40px;
  background: var(--gray-008);
`;
export const PhoneNumber = styled.div`
  width: 100%;
  display: flex;
  align-items: left;
  flex-direction: row;
  margin-bottom: 15px;
`;
export const Space = styled.div`
  height: 50px;
  width: 10%;
  background-color: var(--gray-008);
  display: flex;
  align-items: left;
`;
export const CardData = styled.div`
  display: flex;
  width: 37%;
  height: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: var(--gray-008);
`;
export const Content = styled.div`
  display: flex;
  background-color: #eeeff6;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
export const Left = styled.div`
  padding-top: 40px;
  padding: 10px;
  flex: 1;
`;
export const Right = styled.div`
  padding: 10px;
  padding-top: 40px;
  flex: 3;
`;

export const Title = styled.h3`
  font-family: 'Nexa-Heavy';
  color: var(--gray-001);
  margin-bottom: 30px;
  margin-top: 30px;
`;
export const Button = styled.button`
  width: 100%;
  height: 40px;
  border-radius: 30px;
  background-color: #00a4a1;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  font-family: 'Helvetica-Normal';
  &:hover {
    background-color: #00a4a1;
  }
  &:active {
    background-color: #00ccc9;
  }
`;

export const LeftInput = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  margin-right: 15px;
`;
export const MiddleInput = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  margin-right: 15px;
`;
export const RightInput = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 2;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;
