import produce from 'immer';

export const INITIAL_STATE = {
  accounts: { subAccounts: [{ cardStatus: '' }] },
  numberPages: null,
  currentPage: 1,
  currentAccount: { cardStatus: '' },
  currentDataAccount: null,
  currentCard: { cardsResult: [{ status: '' }] },
  currentLimitCard: null,
};

export default function RelationshipReducer(
  state = INITIAL_STATE,
  action: any
) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@relationship/SET_ACCOUNTS': {
        draft.accounts = action.data;

        const pages = action.data.totalSubAccounts;
        let number = Math.ceil(pages / 50);
        let array: any = [];
        for (let i = 0; i < parseInt(JSON.stringify(number)); i++) {
          array.push({ page: i + 1 });
        }
        draft.numberPages = array;

        break;
      }
      case '@relationship/SET_CURRENT_ACCOUNT': {
        draft.currentAccount = action.currentAccount;
        break;
      }
      case '@relationship/SET_CURRENT_CARD_STATUS': {
        draft.currentCard.cardsResult[0].status = action.status;
        break;
      }
      case '@relationship/SET_CURRENT_DATA_ACCOUNT': {
        draft.currentDataAccount = action.data;
        break;
      }
      case '@relationship/SET_CURRENT_CARD': {
        draft.currentCard = action.card;
        break;
      }
      case '@relationship/SET_CURRENT_LIMIT_CARD': {
        draft.currentLimitCard = action.currentLimit;
        break;
      }
      case '@relationship/UPDATE_ACCOUNT_STATUS': {
        const accountIndex = draft.accounts.subAccounts.findIndex(
          (account: any) => account.account === action.accountId
        );

        if (accountIndex >= 0) {
          draft.accounts.subAccounts[accountIndex].cardStatus = action.status;
        }

        break;
      }
      case '@relationship/SET_CURRENT_PAGE': {
        draft.currentPage = action.currentPage;
        break;
      }
      default:
    }
  });
}
