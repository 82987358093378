import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 100px;
  width: 100%;
`;

export const Left = styled.div`
  flex: 2;
  align-self: center;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const Middle = styled.div`
  flex: 3;
  align-self: center;
`;
export const Right = styled.div`
  flex: 1;
  align-self: center;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const Title = styled.label`
  font-family: 'Nexa-Heavy';
  font-size: 1.2em;
  color: var(--gray-001);
  @media (max-width: 768px) {
    font-size: 0.9em;
  }
`;

export const SelectButton = styled.select`
  width: 63%;
  height: 45px;
  border-radius: 22px;
  border-width: 2px;
  border-color: var(--primary);
  color: var(--primary);
  margin-left: 20px;
  background-color: transparent;
  padding: 0 10px;
  outline: 0 !important;
`;
