import React, { useEffect, useState } from 'react';
import Loading from 'components/Loading/Loading.component';
import ChooseCompanyScreen from './ChooseCompany.screen';
import { getCompanys } from 'store/modules/company/company.endpoints';
import { getAccounts } from 'store/modules/relationship/relationship.endpoints';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setCurrentCompany } from 'store/modules/company/company.actions';
import { setAccounts } from 'store/modules/relationship/relationship.actions';
import { connect } from 'react-redux';

interface ICompany {
  title: string;
  value: string;
  relationCode: string;
}

function ChooseCompanyController({ companyCnpj }: { companyCnpj: string }) {
  const [loading, setLoading] = useState(true);
  const [companys, setCompanys] = useState([]);
  const [company, setCompany] = useState<ICompany | any>();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    findCompany();
  }, []);

  const findCompany = async () => {
    try {
      dispatch(setAccounts([]));
      const result = await getCompanys();

      const companys = result.companies;

      const newObjectCompany = companys.map((company: any) => {
        return {
          title: company.corporateName,
          value: company.cnpj,
          relationCode: company.relationCode,
        };
      });

      setCompanys(newObjectCompany);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleAccess = (value: string) => {
    if (!value) {
      return;
    }

    setCompany(JSON.parse(value));

    dispatch(setCurrentCompany(JSON.parse(value)));
  };

  const handleHome = async () => {
    if (!company) {
      return alert('É obrigatório selecionar uma empresa para continuar.');
    }

    const response = await getAccounts(companyCnpj, company.relationCode);

    dispatch(setAccounts(response));

    return history.push('/home');
  };

  const handlers = {
    handleAccess,
    companys,
    handleHome,
  };

  return (
    <>
      {loading ? (
        <Loading size={40} icon={true} />
      ) : (
        <ChooseCompanyScreen handlers={handlers} />
      )}
    </>
  );
}

const mapStateToProps = (state: any) => ({
  companyCnpj: state.company.cnpj,
});
export default connect(mapStateToProps)(ChooseCompanyController);
