import {
    ToggleGreen,
    Label,
    Container,
    TopTab,
    BottomTab,
    Left,
    Right,
} from './DataCardInfo.styles'
import React from 'react'

const DataCardInfo = ({
    setShowFormInit,
    showFormInit,
}: {
    setShowFormInit: any;
    showFormInit: boolean;
}) => {
    return (
        <Container>
            <TopTab>
                <Left>
                    <Label onClick={() => setShowFormInit(true)}>
                        Dados Pessoais
                    </Label>
                </Left>
                <Right>
                    <ToggleGreen
                        onClick={() => setShowFormInit(true)}
                        color={showFormInit ? '#04A4A1' : '#DFE0E6'}
                    />
                </Right>
            </TopTab>
            <BottomTab>
                <Left>
                    <Label onClick={() => setShowFormInit(false)}>
                        Endereço de Entrega do Cartão
                    </Label>
                </Left>
                <Right style={{justifyContent:"flex-start"}}>
                    <ToggleGreen
                        onClick={() => setShowFormInit(false)}
                        color={showFormInit ? '#DFE0E6' : '#04A4A1'}
                    />
                </Right>
            </BottomTab>
        </Container>
    )
}

export default DataCardInfo
