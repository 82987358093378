import requester from '../requester';

const serviceRelationship = {
  accounts: {
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: '/v1/corporate/accounts',
    headers: {
      'Content-Type': 'application/json',
      environmentName: process.env.REACT_APP_ENVIRONMENT_NAME,
      systemName: process.env.REACT_APP_SYSTEM_NAME,
      orgNumber: process.env.REACT_APP_ORG_NUMBER,
      cnpj: '{{cnpj}}',
      startAccount: '{{startAccount}}',
      direction: '{{direction}}',
      Authorization: '{{token}}',
    },
    method: 'get',
  },
  findCard: {
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: '/v1/cards/{{documentNumber}}',
    headers: {
      'Content-Type': 'application/json',
      environmentName: process.env.REACT_APP_ENVIRONMENT_NAME,
      systemName: process.env.REACT_APP_SYSTEM_NAME,
      org: process.env.REACT_APP_ORG_NUMBER,
      relationshipNumber: process.env.REACT_APP_RELATIONSHIP_NUMBER,
      product: process.env.REACT_APP_PRODUCT,
      Authorization: '{{token}}',
    },
    method: 'get',
  },
  findDataAccount: {
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: '/v1/accounts/data/{{uuid}}',
    headers: {
      'Content-Type': 'application/json',
      environmentName: process.env.REACT_APP_ENVIRONMENT_NAME,
      systemName: process.env.REACT_APP_SYSTEM_NAME,
      org: process.env.REACT_APP_ORG_NUMBER,
      relationshipNumber: process.env.REACT_APP_RELATIONSHIP_NUMBER,
      product: process.env.REACT_APP_PRODUCT,
      Authorization: '{{token}}',
    },
    method: 'get',
  },
};

function getToken() {
  const authenticateJson = localStorage.getItem('persist:Fidis:');
  const authenticate = JSON.parse(authenticateJson || '');
  const { token } = JSON.parse(authenticate.authenticate || '');

  return token;
}

export async function getAccounts(companyCnpj: string, relationCode: string) {
  let { accounts }: { accounts: any } = serviceRelationship;

  accounts.headers.Authorization = getToken();
  accounts.headers.cnpj = companyCnpj;
  accounts.headers.relationCode = relationCode;
  accounts.headers.startAccount = '';
  accounts.headers.direction = '';

  const response = await requester(accounts);

  return response.data;
}

export async function nextPage({
  companyCnpj,
  startAccount,
  direction,
}: {
  companyCnpj: string;
  startAccount: string;
  direction: string;
}) {
  let { accounts }: { accounts: any } = serviceRelationship;
  accounts.headers.Authorization = getToken();
  accounts.headers.cnpj = companyCnpj;
  accounts.headers.startAccount = startAccount;
  accounts.headers.direction = direction;

  const response = await requester(accounts);

  return response.data;
}

export async function getCardByDocumentNumber(documentNumber: string) {
  let { findCard }: { findCard: any } = serviceRelationship;
  findCard.headers.Authorization = getToken();

  const endpoint = findCard.endpoint.replace(
    '{{documentNumber}}',
    documentNumber
  );
  const headers = { ...findCard, endpoint };

  const response = await requester(headers);
  return response;
}

export async function getDataAccount(uuid: string) {
  let { findDataAccount }: { findDataAccount: any } = serviceRelationship;
  findDataAccount.headers.Authorization = getToken();

  const endpoint = findDataAccount.endpoint.replace('{{uuid}}', uuid);
  const headers = { ...findDataAccount, endpoint };

  const response = await requester(headers);
  return response;
}
