import React from 'react';
import { Container, Canceled, Title, Label, Button } from './CancelCard.styles';

function CancelCardScreen({ handlers }: { handlers: any }) {
  const { handleCancelCard, accountStatusDescription } = handlers;
  return (
    <Container>
      {accountStatusDescription === 'CANCELADA' ? (
        <>
          <Canceled>
            <Title>Essa conta se encontra CANCELADA.</Title>
          </Canceled>
        </>
      ) : (
        <>
          <Title>Cancelar conta cartão</Title>
          <Label>Confirmar o cancelamento da conta cartão?</Label>
          <Button onClick={handleCancelCard}>Confirmar cancelamento</Button>
        </>
      )}
    </Container>
  );
}

export default CancelCardScreen;
