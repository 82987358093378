import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const Title = styled.h3`
  font-family: 'Nexa-Heavy';
  color: var(--gray-001);
  margin-bottom: 30px;
  margin-top: 30px;
`;

export const Label = styled.label`
  font-family: 'Helvetica-Normal';
  color: var(--gray-001);
  margin-bottom: 30px;
`;
export const Button = styled.button`
  width: 60%;
  height: 40px;
  border-radius: 30px;
  background-color: var(--primary);
  border: none;
  color: var(--white);
  cursor: pointer;
  font-family: 'Helvetica-Normal';
  &:hover {
    background-color: var(--primary);
  }
  &:active {
    background-color: var(--primary-03);
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Row = styled.div`
  flex-direction: row;
  margin-bottom: 5px;
`;

export const InputRadio = styled.input`
  margin-right: 20px;
`;

export const InputLabel = styled.label`
  font-family: Helvetica-Medium;
  color: var(--gray-001);
`;
