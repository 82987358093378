import React, { useState } from 'react';
import RequestCardScreen from './RequestCard.screen';
import { postCreateAccountCorporate } from 'store/modules/client/client.endpoints';
import {
  validationDocument,
  cleanDocument,
  isValidDate,
  nameIsvValid,
  validateEmail,
} from 'utils/validations/validator.utils';
import moment from 'moment';
import history from 'services/history';
import { connect } from 'react-redux';

function RequestCardController({ relationCode }: { relationCode: string }) {
  const [typeAddress, setTypeAddress] = useState('');
  const [creditCard, setCreditCard] = useState('');
  const [logo, setLogo] = useState('');
  const [maritalStatus, setMaritalStatus] = useState(1);
  const [clientName, setClientName] = useState('');
  const [birthDate, setBirthDate] = useState('');
  const [gender, setGender] = useState('M');
  const [documentNumber, setDocumentNumber] = useState('');
  const [motherName, setMotherName] = useState('');
  const [email, setEmail] = useState('');
  const [embossName, setEmbossName] = useState('');
  const [addressHome, setAddressHome] = useState('');
  const [addressHomeNumber, setAddressHomeNumber] = useState('');
  const [addressHomecomplement, setAddressHomecomplement] = useState('');
  const [district, setDistrict] = useState('');
  const [cityHome, setCityHome] = useState('');
  const [cityHomeCode, setCityHomeCode] = useState('');
  const [addressWork, setAddressWork] = useState('');
  const [addressNumberWork, setAddressNumberWork] = useState('');
  const [addressWorkComplement, setAddressWorkComplement] = useState('');
  const [districtWork, setDistrictWork] = useState('');
  const [cityWork, setCityWork] = useState('');
  const [cityCodeWork, setCityCodeWork] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [zipCodeWork, setZipCodeWork] = useState('');
  const [dddHome, setDddHome] = useState('');
  const [phoneNumberHome, setPhoneNumberHome] = useState('');
  const [dddCellPhone, setDddCellPhone] = useState('');
  const [cellPhone, setCellPhone] = useState('');
  const [creditLimit, setCreditLimit] = useState('');
  const [valueIncome, setValueIncome] = useState('0.00');
  const [dayDue, setDayDue] = useState('22');
  const [companyName, setCompanyName] = useState('');
  const [pctCode, setPctCode] = useState('1');
  const [annuityCode, setAnnuityCode] = useState('1');
  const [discountCode, setDiscountCode] = useState('1');
  const [rateCollectionType, setRateCollectionType] = useState('');
  const [rateDiscountPercentage, setRateDiscountPercentage] = useState('');
  const [rmcValue, setRmcValue] = useState('');
  const [registrationNumber, setRegistrationNumber] = useState('');
  const [contractNumber, setContractNumber] = useState('');
  const [line4Emboss, setLine4Emboss] = useState('');
  const [mainAccount, setMainAccount] = useState('N');

  function generateUUID() {
    var d = new Date().getTime();
    var d2 = (performance && performance.now && performance.now() * 1000) || 0;
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        var r = Math.random() * 16;
        if (d > 0) {
          r = (d + r) % 16 | 0;
          d = Math.floor(d / 16);
        } else {
          r = (d2 + r) % 16 | 0;
          d2 = Math.floor(d2 / 16);
        }
        return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
      }
    );
  }

  const handleCreateAccount = async () => {
    if (
      !documentNumber ||
      !clientName ||
      !embossName ||
      !motherName ||
      !creditLimit ||
      !birthDate ||
      !email ||
      !dddCellPhone ||
      !cellPhone ||
      !typeAddress
    ) {
      return alert('Por favor verifique seus dados pessoais.');
    }
    if (
      typeAddress === 'R' &&
      (!zipCode ||
        !addressHome ||
        !addressHomeNumber ||
        !district ||
        !cityHomeCode ||
        !cityHome)
    ) {
      return alert('Por favor os dados do endereço resiencial.');
    }
    if (
      typeAddress === 'C' &&
      (!zipCodeWork ||
        !addressWork ||
        !addressNumberWork ||
        !districtWork ||
        !cityCodeWork ||
        !cityWork)
    ) {
      return alert('Por favor os dados do endereço comercial.');
    }
    if (!validationDocument(cleanDocument(documentNumber))) {
      return alert('CPF inválido');
    }
    if (!isValidDate(birthDate)) {
      return alert('Data de nascimento inválido');
    }
    if (!isValidDate(birthDate)) {
      return alert('Data de nascimento inválido');
    }
    if (!nameIsvValid(clientName)) {
      return alert(
        'O nome não pode conter números ou caracteres especiais,\ntais como: " ! ? @ # $ % ˆ & * ( ) - + , . ` { } : ;'
      );
    }
    if (!nameIsvValid(motherName)) {
      return alert(
        'O nome da mãe não pode conter números ou caracteres especiais,\ntais como: " ! ? @ # $ % ˆ & * ( ) - + , . ` { } : ;'
      );
    }
    if (!validateEmail(email)) {
      return alert('Email inválido');
    }
    if (parseInt(dayDue) > 31) {
      return alert('Data de vencimento inválido');
    }
    try {
      let data = {};
      if (typeAddress === 'C') {
        data = {
          uuid: generateUUID(),
          org: process.env.REACT_APP_ORG_NUMBER,
          logo,
          clientName,
          birthDate: moment(birthDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
          maritalStatus,
          gender,
          documentNumber: cleanDocument(documentNumber),
          motherName,
          email,
          embossName,
          addressHome: addressWork,
          addressHomeNumber: addressNumberWork,
          addressHomecomplement: addressWorkComplement,
          district: districtWork,
          cityHome: cityWork,
          cityHomeCode: cityCodeWork,
          zipCode: zipCodeWork.split('-').join(''),
          addressWork,
          addressNumberWork,
          addressWorkComplement,
          districtWork: districtWork,
          cityWork,
          cityCodeWork,
          zipCodeWork: zipCodeWork.split('-').join(''),
          dddHome: !dddHome && dddCellPhone ? dddCellPhone : dddHome,
          phoneNumberHome:
            !phoneNumberHome && cellPhone
              ? cellPhone.split('-').join('')
              : phoneNumberHome.split('-').join(''),
          dddCellPhone,
          cellPhone: cellPhone.split('-').join(''),
          pctCode,
          annuityCode,
          discountCode,
          rateCollectionType,
          rateDiscountPercentage,
          rmcValue,
          registrationNumber,
          contractNumber,
          relationCode: relationCode,
          mainAccount,
          creditLimit: creditLimit.split(',').join(''),
          typeAddress,
          dayDue,
          valueIncome: valueIncome.split(',').join(''),
        };
      } else {
        data = {
          uuid: generateUUID(),
          org: process.env.REACT_APP_ORG_NUMBER,
          logo,
          clientName,
          birthDate: moment(birthDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
          maritalStatus,
          gender,
          documentNumber: cleanDocument(documentNumber),
          motherName,
          email,
          embossName,
          addressHome,
          addressHomeNumber,
          addressHomecomplement,
          district: district.substring(0, 15),
          cityHome,
          cityHomeCode,
          zipCode: zipCode.split('-').join(''),
          dddHome: !dddHome && dddCellPhone ? dddCellPhone : dddHome,
          phoneNumberHome:
            !phoneNumberHome && cellPhone
              ? cellPhone.split('-').join('')
              : phoneNumberHome.split('-').join(''),
          dddCellPhone,
          cellPhone: cellPhone.split('-').join(''),
          pctCode,
          annuityCode,
          discountCode,
          rateCollectionType,
          rateDiscountPercentage,
          rmcValue,
          registrationNumber,
          contractNumber,
          relationCode: relationCode,
          mainAccount,
          creditLimit: creditLimit.split(',').join(''),
          typeAddress,
          dayDue,
          valueIncome: valueIncome.split(',').join(''),
        };
      }
      await postCreateAccountCorporate(data);
      alert('Proposta gerada com sucesso!');
      history.push('/home');
    } catch (error) {
      if (
        error?.response?.data?.code === '38' ||
        error?.response?.data?.code === 38
      ) {
        return alert('A relação proposta não tem limite disponível.');
      }
      if (
        error?.response?.data?.code === '6' ||
        error?.response?.data?.code === 6
      ) {
        return alert(
          'Não foi possível solicitar cartão, CPF não autorizado para essa operação.'
        );
      }
      alert('Não foi possível solicitar o cartão.');
    }
  };

  const handleCreditCard = (card: any) => {
    setCreditCard(card);
    if (card === 'green') {
      return setLogo('001');
    }
    return setLogo('002');
  };

  const handlers = {
    setTypeAddress,
    typeAddress,
    creditCard,
    handleCreditCard,
    logo,
    setLogo,
    maritalStatus,
    setMaritalStatus,
    clientName,
    setClientName,
    birthDate,
    setBirthDate,
    gender,
    setGender,
    documentNumber,
    setDocumentNumber,
    motherName,
    setMotherName,
    email,
    setEmail,
    embossName,
    setEmbossName,
    addressHome,
    setAddressHome,
    addressWork,
    setAddressWork,
    addressHomeNumber,
    setAddressHomeNumber,
    addressNumberWork,
    setAddressNumberWork,
    addressHomecomplement,
    setAddressHomecomplement,
    addressWorkComplement,
    setAddressWorkComplement,
    district,
    setDistrict,
    districtWork,
    setDistrictWork,
    cityHome,
    setCityHome,
    cityHomeCode,
    setCityHomeCode,
    zipCode,
    setZipCode,
    zipCodeWork,
    setZipCodeWork,
    dddHome,
    setDddHome,
    dddCellPhone,
    setDddCellPhone,
    cellPhone,
    setCellPhone,
    phoneNumberHome,
    setPhoneNumberHome,
    creditLimit,
    setCreditLimit,
    valueIncome,
    setValueIncome,
    dayDue,
    setDayDue,
    companyName,
    setCompanyName,
    line4Emboss,
    setLine4Emboss,
    cityWork,
    setCityWork,
    cityCodeWork,
    setCityCodeWork,
    handleCreateAccount,
  };
  return <RequestCardScreen handlers={handlers} />;
}

const mapStateToProps = (state: any) => ({
  relationCode: state.relationship.accounts.relationshipNumber,
});
export default connect(mapStateToProps)(RequestCardController);
