import React, { useState } from 'react';
import {
  PhoneNumber,
  Space,
  Left,
  Right,
  Title,
  Button,
  Content,
  Row,
  LeftInput,
  MiddleInput,
  RightInput,
} from './CarrierData.styles';
import InputLabel from '../../components/InputLabel';
import DataCardInfo from 'components/DataCardInfo/DataCardInfo.components';
import Loading from 'components/Loading/Loading.component';
import InputSelect from 'components/InputSelect/InputSelect.component';

const CarrierDataScreen = ({ handlers }: { handlers: any }) => {
  let {
    name,
    birthDate,
    motherName,
    email,
    telephone,
    cellPhone,
    showFormInit,
    setShowFormInit,
    cep,
    setCep,
    number,
    setNumber,
    complement,
    setComplement,
    address,
    setAddress,
    state,
    setState,
    city,
    setCity,
    error,
    district,
    setDistrict,
    IAddress,
    buttonLoading,
    findCepAndSetValues,
    dddCellPhone,
    setDddCellPhone,
    ddd,
    setDdd,
    setTelephone,
    handleUpdateClient,
    setName,
    setBirthDate,
    setMotherName,
    setEmail,
    setCellPhone,
    gender,
    setGender,
  } = handlers;

  const optionsGender = [
    { title: 'Masculino', value: 'M' },
    { title: 'Feminino', value: 'F' },
    { title: 'Outros', value: 'O' },
  ];

  const genderTranslater: any = {
    '1': 'Masculino',
    '2': 'Feminino',
    '0': 'Outros',
  };
  const genderLabel = genderTranslater[gender];

  return (
    <>
      {error ? (
        <Title>{error}</Title>
      ) : (
        <Content>
          <Left>
            <DataCardInfo
              setShowFormInit={setShowFormInit}
              showFormInit={showFormInit}
            />
          </Left>
          <Right>
            {showFormInit ? (
              <>
                <InputLabel
                  onChange={setName}
                  label="Nome"
                  type="text"
                  placeholder=""
                  value={name}
                />

                <InputLabel
                  onChange={setBirthDate}
                  label="Data de nascimento"
                  type="date"
                  value={birthDate}
                />

                <InputLabel
                  label="Nome da mãe"
                  type="text"
                  placeholder=""
                  onChange={setMotherName}
                  value={motherName}
                />

                <InputLabel
                  onChange={setEmail}
                  label="E-mail"
                  type="email"
                  placeholder=""
                  value={email}
                />
                <InputSelect
                  defaultValue={genderLabel}
                  onChange={setGender}
                  label="Gênero"
                  options={optionsGender}
                />
                <Row>
                  <LeftInput>
                    <InputLabel
                      onChange={setDddCellPhone}
                      label="DDD"
                      placeholder="000"
                      value={dddCellPhone}
                      number={true}
                      maxLength={4}
                    />
                  </LeftInput>

                  <RightInput>
                    <InputLabel
                      onChange={setCellPhone}
                      label="Celular"
                      type="tel"
                      placeholder="00000-0000"
                      pattern="([0-9]{2}) [0-9]{5}-[0-9]{4}"
                      value={cellPhone}
                      number={true}
                      maxLength={9}
                    />
                  </RightInput>
                </Row>
                <Row>
                  <LeftInput>
                    <InputLabel
                      onChange={setDdd}
                      label="DDD"
                      type="tel"
                      placeholder="(00) 0000-0000"
                      value={ddd}
                      number={true}
                      maxLength={4}
                    />
                  </LeftInput>

                  <RightInput>
                    <InputLabel
                      onChange={setTelephone}
                      label="Telefone"
                      type="tel"
                      placeholder="0000-0000"
                      pattern="([0-9]{2}) [0-9]{5}-[0-9]{4}"
                      value={telephone}
                      number={true}
                      maxLength={9}
                    />
                  </RightInput>
                </Row>
                {buttonLoading ? (
                  <Loading size={25} />
                ) : (
                  <Button onClick={handleUpdateClient}>
                    Salvar Alterações
                  </Button>
                )}
              </>
            ) : (
              <>
                {' '}
                <InputLabel
                  label="Cep"
                  placeholder="00000-000"
                  onChange={findCepAndSetValues}
                  value={cep}
                  number={true}
                  maxLength={9}
                />
                <InputLabel
                  label="Logradouro"
                  placeholder=""
                  onChange={setAddress}
                  value={address}
                />
                <InputLabel
                  label="Numero"
                  type="number"
                  placeholder=""
                  onChange={setNumber}
                  value={number}
                  number={true}
                  maxLength={10}
                />
                <InputLabel
                  label="Complemento"
                  type="text"
                  placeholder=""
                  onChange={setComplement}
                  value={complement}
                />
                <InputLabel
                  label="Bairro"
                  type="text"
                  placeholder=""
                  onChange={setDistrict}
                  value={district}
                />
                <PhoneNumber>
                  <InputLabel
                    label="Estado"
                    type="tel"
                    placeholder=""
                    pattern=""
                    onChange={setState}
                    value={state}
                  />
                  <Space />
                  <InputLabel
                    label="Cidade"
                    type="tel"
                    placeholder=""
                    pattern=""
                    onChange={setCity}
                    value={city}
                  />
                </PhoneNumber>
                {buttonLoading ? (
                  <Loading size={25} />
                ) : (
                  <Button onClick={IAddress}>Salvar Alterações</Button>
                )}
              </>
            )}
          </Right>
        </Content>
      )}
    </>
  );
};

export default CarrierDataScreen;
