import styled, { keyframes } from 'styled-components'

type Size = {
    size:Number
}

const rotate = keyframes`
  from {
    transform: rotate(0deg);
    opacity: 1;
  }

  to {
    transform: rotate(360deg);
    opacity: 0;
  }
`

export const Container = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 100px;
`

export const Spinner = styled.img<any>`
    animation: ${rotate} 1s infinite;
    height: ${(props) => props.size * 2}px;
    width: ${(props) => props.size * 2}px;
    position: absolute;
`
export const LogoIcon = styled.img<any>`
    width: ${(props) => props.size}px;
    height: ${(props) => props.size}px;
`
