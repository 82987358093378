import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  flex: 1;
  flex-direction: column;
`;

export const Title = styled.h3`
  font-family: 'Nexa-Heavy';
  color: var(--gray-001);
  margin-bottom: 30px;
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
`;

export const Label = styled.label`
  font-family: 'Helvetica-Normal';
  color: var(--gray-001);
  margin-bottom: 30px;
`;
export const Button = styled.button`
  width: 60%;
  height: 40px;
  border-radius: 30px;
  background-color: #fff;
  color: var(--gray-001);
  border-width: 1px;
  border-color: var(--gray-001);
  font-family: 'Helvetica-Medium';
  &:hover {
    // background-color: #00a4a1;
  }
  &:active {
    // background-color: #00ccc9;
  }
`;

export const Header = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
