import styled, { createGlobalStyle, css } from "styled-components";

export const Button = styled.button.attrs({type : 'submit'})`
    width: 100%;
    height: 45px;
    border-radius: 30px;
    background-color: var(--primary);
    border: none;
    color: #fff;
    font-family: "Helvetica-Medium";
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.6s;

    &:hover {
        background-color: var(--primary-01);
    }
    &:active {
        background-color: var(--primary);
    }
`

