import React, { useState } from 'react';
import {
  Container,
  Content,
  LogoIcon,
  Header,
  Body,
  Left,
  Right,
  Title,
  Close,
} from './Drawer.styles';
import logo from 'assets/icons/MarcaColor.png';
import { sidebarRoutes } from 'routes/Menus';
import history from 'services/history';
import close from 'assets/icons/close.png';
import { useDispatch } from 'react-redux';
import { signOut } from 'store/modules/authenticate/authenticate.actions';

function DrawerComponent({ handlers }: { handlers: any }) {
  let { setDrawer, drawer } = handlers;
  const dispatch = useDispatch();

  const handleSignOut = () => {
    dispatch(signOut());

    history.push('/');
  };

  const handleOnClick = (item: any) => {
    if (item.disabled) {
      return;
    }
    setDrawer(false);
    sidebarRoutes.map((item) => {
      item.active = false;
    });
    item.active = true;
    history.push(item.link);
  };

  let colorGreen = 'var(--primary)';
  let colorGray = 'var(--gray-003)';

  let translateY = '-100%';

  if (drawer) {
    translateY = '0';
  }

  return (
    <Container style={{ transform: `translateY(${translateY})` }}>
      <Content>
        <Header>
          <Left>
            <LogoIcon src={logo} />
          </Left>
          <Right>
            <Close src={close} onClick={() => setDrawer(false)} />
          </Right>
        </Header>
        <Body>
          {sidebarRoutes.map((route, index) => {
            return (
              <Title
                onClick={() => handleOnClick(route)}
                style={{
                  color: route.active ? colorGreen : colorGray,
                }}
                key={index}
              >
                {route.title}
              </Title>
            );
          })}
          <Title onClick={handleSignOut}>Sair</Title>
        </Body>
      </Content>
    </Container>
  );
}

export default DrawerComponent;
