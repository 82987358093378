import React from 'react';
import {
  Container,
  Title,
  Description,
  Top,
  Middle,
  Bottom,
} from './ChooseCompany.styles';
import Select from './components/Select/Select.component';
import Button from '../../components/Button';

const ChooseCompanyScreen = ({ handlers }: { handlers: any }) => {
  const { companys, handleAccess, handleHome } = handlers;

  const description = `Selecione a empresa que você deseja acessar.`;

  return (
    <Container>
      <Top>
        <Title>Escolha a empresa</Title>
        <Description>{description}</Description>
      </Top>
      <Middle>
        <Select
          onChange={handleAccess}
          placeholder=""
          backgroundColor="#F5F6FA"
          options={companys}
          defaultLabel="Escolha uma empresa"
        />
      </Middle>
      <Bottom>
        <Button onClick={handleHome}>ACESSAR</Button>
      </Bottom>
    </Container>
  );
};

export default ChooseCompanyScreen;
