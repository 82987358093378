import React from 'react';
import { Route, Switch } from 'react-router-dom';
import CreateUser from 'containers/CreateUser/CreateUser.controller';
import ManageUser from 'containers/ManageUser/ManageUser.controller';

function ManageUserRouter() {
  return (
    <Switch>
      <Route path="/home/user/management/manage" exact component={ManageUser} />
      <Route path="/home/user/management" exact component={CreateUser} />
    </Switch>
  );
}

export default ManageUserRouter;
