import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

const PrivateRoute = ({
  token,
  component: Component,
  ...rest
}: {
  token: string;
  component: any;
  exact?: boolean;
  path?: string;
}) => {
  return (
    <Route
      {...rest}
      render={(props: any) =>
        token ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        )
      }
    />
  );
};

const mapStateToProps = (state: any) => ({
  token: state.authenticate.token,
});
export default connect(mapStateToProps)(PrivateRoute);
